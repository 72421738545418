import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { amber } from '@mui/material/colors';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { appTheme } from '../theme/colorTheme';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const InformationDailog = (props) => {
  const navigate = useNavigate();
  const handleOk = () => {
    if (props.status === 'success' || props.status === 'error') {
      if (!!props.navigateUrl) {
        navigate(props.navigateUrl)
      }
      props.setOpenInfo(false)
    } else {
      props.confirmation(props?.isPrefrred);
      props.setOpenInfo(false)
    }


  }
  const handleClose = () => {
    props.setOpenInfo(false)
  }

  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: appTheme.color3
          }
        }}
        open={props.openInfo}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ color: appTheme.color1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {
            props.status === 'success' ? <CheckCircleRoundedIcon fontSize='large' fontWeight='800' color="success" /> :
              props.status === 'error' ? <ErrorRoundedIcon fontSize='large' fontWeight='800' color="error" /> :
                props.status === 'warning' ? <WarningRoundedIcon fontSize='large' fontWeight='800' color="warning" /> : ''
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ color: appTheme.color1 }}>
            <Typography variant="caption" display="block" fontSize={12}>
              {props.statusMessage}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button onClick={handleOk} sx={{
            fontSize: 11,
            color: appTheme.color0, bgcolor: appTheme.color12,
            "&:hover": {
              bgcolor: appTheme.color12
            },
            focusVisible: false
          }}>{props.buttonTextConfirm}</Button>
          {props.status === 'warning' ? <Button sx={{
            fontSize: 11,
            color: appTheme.color0, bgcolor: appTheme.color12,
            "&:hover": {
              bgcolor: appTheme.color12
            },
            focusVisible: false
          }} onClick={handleClose}>{props.buttonTextCancel}</Button> : ''}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InformationDailog
