import React, { createRef, useEffect, forwardRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import NoData from "./NoData";
import { getImgSrc } from "../constants/Helper";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: appTheme.color11,
  ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  color: appTheme.color1,
}));
const PassbookList = forwardRef((props, ref) => {
  const openTransactionDetailsDailog = (selectedTransaction) => {
    props.onClick(selectedTransaction);
  }
  const transactionList = props?.itemList
  
  return (


    <Item ref={ref} id={props.id}>
      <Stack
        sx={{ color: appTheme.color1, width: '100%', cursor: 'pointer' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          openTransactionDetailsDailog(transactionList)
        }}
      >
        <Box>
          <ListItem
            sx={{ color: appTheme.color1, padding: .5, pl: 1, pr: 1 }}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <img
                style={{
                  width: '45px', borderRadius: 50, backgroundColor: appTheme.color0, padding: 5, justifyContent: "center",
                  alignItems: "center", verticalAlign: 'middle'
                }}
                src={!!getImgSrc(transactionList?.logoUrl) ? getImgSrc(transactionList.logoUrl) : require('../img/no-pictures.png')}
                alt="logo"
                loading="lazy"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  {transactionList?.title && <Typography
                    sx={{ display: "inline" }}
                    component="div"
                    display="block"
                    variant="caption"
                    fontSize={11}
                  >
                    {
                     transactionList.title
                    }

                  </Typography>}
                  {/* <Typography
                  display="block"
                  component="span"
                  variant="caption"
                  fontSize={10}
                >
                  User ID: {transactionList?.userId}
                </Typography> */}
                {transactionList?.userName && <Typography
                    display="block"
                    component="span"
                    variant="caption"
                    color={appTheme.color6}
                    fontSize={10}
                    sx={{textTransform:'uppercase'}}
                  >
                    {transactionList.userName}
                  </Typography>}
                  {transactionList?.requestedDate && <Typography
                    display="block"
                    component="span"
                    variant="caption"
                    color={appTheme.color6}
                    fontSize={10}
                  >
                    {transactionList.requestedDate}
                  </Typography>}
                 { transactionList?.remark && <Typography
                    component="div"
                    display="inline-block"
                    variant="caption"
                    color={`white`}
                    fontSize={10}
                    sx={{backgroundColor:appTheme.color17, textTransform:'uppercase', padding:.5, borderRadius:2}}
                  >
                    {transactionList.remark}
                  </Typography>}
                </React.Fragment>
              }
            />
          </ListItem>
        </Box>
        <Box sx={{
          pr: 1,
          ml: 1
        }}>
          {transactionList?.amount && <Typography
            sx={{ display: "block", textAlign: 'right' }}
            component="span"
            variant="caption"
          >
            {transactionList.amount}
          </Typography>}
          {transactionList?.status && <Typography
            sx={{ display: "block", textAlign: 'right' }}
            component="span"
            variant="caption"
            color={transactionList.textColor}
          >
            {
                transactionList.status
            }
          </Typography>}
        </Box>
      </Stack>


    </Item>



  );
});

const PassbookCardScreen = ({ transactionsData, setPageNumber, perPage, totalNumberOfData }) => {
  const sentinel = createRef(); 
  let navigate = useNavigate();
  let options = {
    root: null,
    rootMargin: "0px 0px -70px 0px",
    threshold: 1,
  };
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
     if(entry.isIntersecting && transactionsData.length >= perPage && transactionsData.length < totalNumberOfData){
      observer.disconnect();
      sentinel.current = null;
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
    })
  }, options);


  const showTransactionDetails = (selectedTransaction) => {
    navigate({
      pathname: '/transactionsDetails',
      search: `?id=${selectedTransaction.id}`,
    })
  }
  useEffect(() => {
    if(transactionsData.length > 0 && transactionsData.length >= perPage && transactionsData.length < totalNumberOfData){
      observer.observe(sentinel.current);
    }
    return () => {
      observer.disconnect();
    }
  }, [transactionsData.length]); // Infinite Scroll: calling useEffect if transactionData is updated to get new ref of sentinel to observe;
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={1}>
          {!!transactionsData && transactionsData?.length !== 0 ? transactionsData.map((item,idx) => {
             if(idx === transactionsData.length - 4){
              return <PassbookList id={item.id} itemList={item} key={item.id} ref={sentinel}  onClick={showTransactionDetails}/>
             }
            return <PassbookList id={item.id} itemList={item} key={item.id} onClick={showTransactionDetails} />
          }) : <NoData noDataText="You don't have any transaction yet." />
          }


        </Stack>
      </Box>
    </>
  );
};

const PassbookCard = React.memo(PassbookCardScreen);

export default PassbookCard;
