const lang = {
    en: {
        PLEASE_ENTER_VALID_PHONE_NUMBER: "Please enter 10 digits valid phone number",
        ENTER_TEN_DIGITS_PHONE_NUMBER: "Enter 10 Digits Phone Number",
        PHONE_NUMBER: "Phone Number",
        CUSTOMER_SUPPORT: "Customer Support",
    },
    hi: {
        PLEASE_ENTER_VALID_PHONE_NUMBER: "कृपया 10 अंकों में सही फ़ोन नंबर दर्ज करें",
        ENTER_TEN_DIGITS_PHONE_NUMBER: "अगले 10 अंकों में फ़ोन नंबर दर्ज करें",
        PHONE_NUMBER: "फ़ोन नंबर",
        CUSTOMER_SUPPORT: "ग्राहक सहायता",
    },
    es: {
        PLEASE_ENTER_VALID_PHONE_NUMBER: "Buscar",
        ENTER_TEN_DIGITS_PHONE_NUMBER: "¿Qué te gustaría ver hoy?",
    }
}

export default lang;