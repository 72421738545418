import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography, Button, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { Box } from "@mui/system";
import axiosService from "../axios-api-kit/axiosService";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaymentDetails from "./PaymentDetails";
import AddPaymentsDailog from "./AddPaymentsDetailsDialog";
import { appTheme } from "../theme/colorTheme";
import { getImgSrc } from "../constants/Helper";
import { useSelector } from "react-redux";

const WithdrawalDetailsScreen = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { wallet_balance, name, phone } = useSelector(store => store?.app?.userInfo);
  const [createIdData, setCreateIdData] = React.useState([]);
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const [isBankDetailsUpdate, setIsBankDetailsUpdate] = React.useState(false);
  const [isSubmitClicked, setIsSubmitButtonClicked] = React.useState(true);

  const handleChange = React.useCallback((panel, isPaymethodAvailable) => (event, isExpanded) => {
    if (isPaymethodAvailable) {
      setExpanded(isExpanded ? panel : false);
    }
  }, []);
  const addNewDetail = React.useCallback((item, isBankUdate) => {
    !!isBankUdate ? setIsBankDetailsUpdate(true) : setIsBankDetailsUpdate(false)
    setSelectedPayment(item);
    setOpenPaymentDialog(true)
  }, []);

  const getData = React.useCallback(() => {
    axiosService.getPaymentMethodTypes().then((response) => {
      setCreateIdData(response.data);
      setIsSubmitButtonClicked(true);
    }).catch(error => {
      setIsSubmitButtonClicked(true);
    })
  }, [])
  const updatedPaymentData = React.useCallback((item, accountIdentifier) => {

    if (item?.type.toLocaleLowerCase() !== 'bank') {
      if(isSubmitClicked){
        setIsSubmitButtonClicked(false);
        const payload = new URLSearchParams()
        payload.append('account_display_name', item.account_display_name);
        payload.append('account_identifier', accountIdentifier);
        axiosService.getUpdateMethodTypes(item.id, payload).then((response) => {
          getData();
        }).catch(error => {
          setIsSubmitButtonClicked(true);
        })
      }
      
    } else {
      addNewDetail(item, true);
    }
  }, [addNewDetail, getData, isSubmitClicked])

  const deletePaymentMethod = React.useCallback((selectedMethod) => {
    if(isSubmitClicked){
      setIsSubmitButtonClicked(false);
      axiosService.deletePaymentMethod(selectedMethod?.id).then((response => {
        getData();
      })).catch((error) => {
        setIsSubmitButtonClicked(true);
      })
    }
   
  }, [getData, isSubmitClicked])

  const updatePreferrence = React.useCallback((selectedType) => {
    if(isSubmitClicked){
      setIsSubmitButtonClicked(false);
      axiosService.updatePreferrence(selectedType?.id).then((response => {
        getData();
      })).catch((error) => {
        setIsSubmitButtonClicked(true);
      })
    }
  }, [getData, isSubmitClicked])


  React.useEffect(() => {
    getData();
  }, [getData])

  return (
    <React.Fragment>
      {!!openPaymentDialog ? <AddPaymentsDailog
        isFromWithdraw={false}
        isBankDetailsUpdate={isBankDetailsUpdate}
        selectedPayment={selectedPayment}
        openPaymentDialog={openPaymentDialog}
        getData={getData}
        setOpenPaymentDialog={setOpenPaymentDialog}
      /> : ''}

      <Stack
        sx={{ color: appTheme.color1, mb: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography
            variant="body2" component="div"
            sx={{ color: appTheme.color7 }}
          >
            {name}
          </Typography>
          <Typography variant="caption" gutterBottom component="div">
            <img
              width="25px"
              style={{ verticalAlign: 'middle' }}
              src={require("../img/icons8-india-48.png")}
              alt="noOffer"
              loading="lazy"
            /> +91-{phone}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography
            variant="caption"
            gutterBottom
            sx={{ color: appTheme.color7 }}
          >
            Amount
          </Typography>
          <Typography variant="h6" component="div">
            ₹{wallet_balance}
          </Typography>
        </Box>
      </Stack>
      {createIdData.length !== 0 ? createIdData.map((item, i) => (
        <Accordion
          key={i}
          expanded={expanded === `${item.id}panel`}
          onChange={handleChange(`${item.id}panel`, item?.payment_methods.length !== 0)}
          sx={{ bgcolor: appTheme.color11, mb: 1, padding: 0, borderRadius: 2 }}
          disableGutters={true}
          square={true}
        >
          <AccordionSummary
            expandIcon={item?.payment_methods.length !== 0 ? <ExpandMoreIcon sx={{ color: appTheme.color1 }} /> : ''}
            aria-controls="panel-content"
            id="panel-header"
            sx={{ padding: 0, pl: .5, pr: .5, width: "100%" }}
          >

            <ListItem
              sx={{ color: appTheme.color1, padding: 0, pl: 1, pr: 1, width: "100%" }}
              secondaryAction={
                item?.payment_methods.length === 0 ?
                  <React.Fragment>
                    <Button
                      onClick={() => addNewDetail(item)}
                      size="small"
                      variant="contained"
                      color="inherit"
                      startIcon={<AddCircleSharpIcon fontSize="small" />}
                      sx={{
                        pr: .7,
                        pl: .7,
                        fontSize: 10,
                        bgcolor: appTheme.color12,
                        "&:hover": {
                          bgcolor: appTheme.color12
                        },
                        color: appTheme.color0,
                        mr: 0,
                        focusVisible: false,
                      }}
                    >
                      Add New
                    </Button>
                  </React.Fragment> : ''
              }
            >
              <ListItemAvatar>

                <img
                  style={{
                    width: '45px', borderRadius: 50, backgroundColor: appTheme.color0, padding: 5, justifyContent: "center",
                    alignItems: "center", verticalAlign: 'middle'
                  }}
                  src={!!getImgSrc(item?.logo_url) ? getImgSrc(item.logo_url) : require('../img/no-pictures.png')}
                  alt="logo"
                  loading="lazy"
                />

              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="caption" component="div">
                    {item.name}
                  </Typography>
                }
              />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>
            <PaymentDetails updatedPaymentData={updatedPaymentData}
              updatePreferrence={updatePreferrence}
              deletePaymentMethod={deletePaymentMethod} data={item.payment_methods} />

            {
              item?.payment_methods.length !== 0 ?

                <Stack spacing={2} sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Button
                    onClick={() => addNewDetail(item)}
                    size="small"
                    variant="contained"
                    color="inherit"
                    startIcon={<AddCircleSharpIcon fontSize="small" />}
                    sx={{
                      pr: .7,
                      pl: .7,
                      fontSize: 10,
                      bgcolor: appTheme.color12,
                      "&:hover": {
                        bgcolor: appTheme.color12
                      },
                      color: appTheme.color0,
                      mr: 0,
                      focusVisible: false,
                    }}
                  >
                    Add New
                  </Button>
                </Stack> : ''
            }

          </AccordionDetails>
        </Accordion>
      )) : <Box sx={{ width: '100%' }}>
        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
      </Box>}
    </React.Fragment>

  );
};

const WithdrawalDetails = React.memo(WithdrawalDetailsScreen)

export default WithdrawalDetails;
