import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Stack, Typography } from "@mui/material";
import { appTheme } from "../../theme/colorTheme";

const CustomTextField = styled(TextField)({
  '& input[type=number]::-webkit-inner-spin-button': {
    display:'none',
    margin: 0
},
  "& label.Mui-focused": {
    color: appTheme.color11,
  },
  "&& input.Mui-disabled": {
    color: appTheme.color10,
    textFillColor: appTheme.color10
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: appTheme.color11,
  },
  "& input::placeholder": {
    fontSize: "12px"
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "1em",
    height: 40,
    "& fieldset": {
      borderColor: appTheme.color11,
    },
    "&:hover fieldset": {
      borderColor: appTheme.color11,
    },
    "&.Mui-focused fieldset": {
      borderColor: appTheme.color11,
    },
    "&.MuiOutlinedInput-root": {
      color: appTheme.color1,
      backgroundColor: appTheme.color11
    }
  },


  '& .MuiInput-underline:before': {
    borderBottomColor: `white`,
  },
  '& .MuiInput-root': {
    '& fieldset': {
      borderColor: `white`,
    },
    '&:hover fieldset': {
      borderColor: `white`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `white`,
    },
    '&.MuiInput-root': {
      color: appTheme.color1,
      backgroundColor: `transparent`,
    },
  },
});

const CustomizedInputs = React.forwardRef((props, ref) => {
  const inputRef = React.useRef();

  const activate = () => {
    return inputRef.current.focus();
}

React.useImperativeHandle(ref, (e) => {
  return {
      focus: activate,
  }
})

  const setInputValue = (value) => {
    props.setData(value)
    if (!!props.checkValidation) {
      props.checkValidation(value)
    }
  }

  return (
    <Stack sx={{ mb: 2, mt: 0 }}>
      <Typography variant="caption" gutterBottom sx={{ color: appTheme.color8 }}>
        {props.label}
      </Typography>
      <Box
      >
        <CustomTextField
          ref={inputRef}
          disabled={props.disabled}
          error={!props.isValidation}
          autoComplete="off"
          helperText={!props.isValidation ? !!props?.errorMsg ? props.errorMsg: 'Incorrect entry.' : ''}
          sx={{
            width: "100%",
            maxWidth: "100%"

          }}
          variant={props.variant}
          InputProps={{ 
            startAdornment: props.startAdornment , autoComplete: 'new-password', form: {
            autoComplete: 'off',
          }, }}
          value={props?.defaultValue}
          required={!!props?.required ? props.required : false}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          type={props.type}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          onKeyPress={props.onKeyPress}
        />
      </Box>
    </Stack>
  );
});

export default CustomizedInputs;
