import React, { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthProvider";

const useDelayUnmount = (isMounted, delayTime) => {
  const {joyTour } = React.useContext(AuthContext);
  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    let timeoutId;
    if(joyTour.status !== 'OPEN'){
      if (isMounted && !showDiv) {
        setShowDiv(true);
      } else if (!isMounted && showDiv) {
        timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
      }
    } else {
      setShowDiv(true);
    }
    
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv, joyTour.status]);
  return showDiv;
}

export default useDelayUnmount;