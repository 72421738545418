import React, { useRef } from "react";
import {
  ThemeProvider,
  CssBaseline,
  Typography,
  Button,
  Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../../theme/theme";
import CustomizedInputs from "../customComponent/CustomInput";
import { AuthContext } from "../../context/AuthProvider";
import axiosService from "../../axios-api-kit/axiosService";
import { appTheme } from "../../theme/colorTheme";
import { useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { getWhatsAppUrl } from "../../constants/Helper";
import { useSelector } from "react-redux";
import whatsAppIcon from '../../img/whatsapp.svg';
import lang from "../../utils/languageConstant";

const LoginWithOtp = (props) => {
  const helpLineWhatsAppNumber = useSelector(store => store?.app?.helpLineWhatsAppNumber);
  const userId = sessionStorage.getItem(localStorageKey.USERID)
  let navigate = useNavigate();
  let timeOut = useRef(null);
  const { name, enteredMobileNumber, password, referralCode } = props.data;
  const { signIn, signUp, getDevToolState } = React.useContext(AuthContext);
  const [otp, setOtp] = React.useState("");
  const [isOtpValid, setOtpValid] = React.useState(true);
  const [counter, setCounter] = React.useState('');

  const checkOtpValidation = (value) => {
    if (value.length > 5) {
      setOtp(value.slice(0, 5))
    } else if (value.length < 5) {
      setOtpValid(false)
    } else {
      setOtpValid(true)
    }
  }

  const onSubmitLogin = () => {
    if (isOtpValid && otp.length !== 0) {
      const payloadData = new URLSearchParams()
      if (!!name && !!enteredMobileNumber && !!password) {
        payloadData.append('name', name);
        payloadData.append('phone', enteredMobileNumber);
        payloadData.append('password', password);
        payloadData.append('otp', otp);
        !!localStorage.getItem(localStorageKey.PUSHID)? payloadData.append('app_push_id', localStorage.getItem(localStorageKey.PUSHID)):payloadData.append('web_push_id', userId)
        if (!!referralCode) payloadData.append('referrer_code', referralCode);
        axiosService.signUp(payloadData).then((resp) => {
          const userData = {
            api_token: resp?.data?.api_token,
            wallet_balance: resp?.data?.wallet_balance || 0,
            name: resp?.data?.name,
            phone: resp?.data?.phone,
            avatar: resp?.data?.avatar,
            id: resp?.data?.id
          }
          signIn(userData)
        }).catch((error) => {
        })
      } else if (!!enteredMobileNumber) {
        payloadData.append('otp', otp);
        payloadData.append('phone', enteredMobileNumber);
        axiosService.login(payloadData).then((resp) => {
          const userData = {
            api_token: resp?.data?.api_token,
            wallet_balance: resp?.data?.wallet_balance || 0,
            name: resp?.data?.name,
            phone: resp?.data?.phone,
            avatar: resp?.data.avatar,
            id: resp?.data?.id
          }
          signUp(userData)
        }).catch((error) => {
        })
      }
    }
  }

  const resendOtp = () => {
    const payloadData = new URLSearchParams()
    if (!!name && !!enteredMobileNumber && !!password) {
      payloadData.append('event', 'signup-otp');
    } else if (!!enteredMobileNumber) {
      payloadData.append('event', 'login-otp');
    }
    payloadData.append('phone', enteredMobileNumber);

    axiosService.sendAuthOtp(payloadData).then((resp) => {
      countdown();
    }).catch((error) => {
    })
  }


  const continueToLogin = (otpCheck) => {
    checkOtpValidation(otpCheck);
    onSubmitLogin();
  }

  const countdown = () => {
    var seconds = 29;
    const tick = () => {
      seconds--;
      setCounter("0:" + (seconds < 10 ? "0" : "") + String(seconds))
      if (seconds > 0) {
        timeOut.current = setTimeout(tick, 1000);
      } else {
        setCounter('')
      }
    }
    tick();
  }


  React.useEffect(() => {
    countdown();

    return(() => {
      clearTimeout(timeOut.current);
    })
  }, [])


  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            color: appTheme.color1,
            mt: 1
          }}
        >
          <Stack
            sx={{
              mt: 10,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 3,
            }}
          >
            <Box
              sx={{
                mb: 6,
                width: 50,
                height: 45,
                borderRadius: 2,
                backgroundColor: appTheme.color11,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../img/otp-bg.png")}
                width="150"
                alt="logo"
                loading="lazy"
              />
            </Box>
            <Typography variant="body1" display="block">
              We have sent an OTP on+91-{enteredMobileNumber}
            </Typography>
          </Stack>


          <CustomizedInputs key={"1"} placeholder="Enter OTP"
            label="Enter OTP*" defaultValue={otp}
            type="number"
            setData={setOtp}
            isValidation={isOtpValid}
            checkValidation={checkOtpValidation}
            onKeyPress={(e) => {
              if(!getDevToolState()){
                continueToLogin(otp);
              }else{
                navigate('/devTools')
              }
            }} />
          <Stack>
            <Button
              variant="contained" onClick={() => {
                if(!getDevToolState()){
                  continueToLogin(otp);
                }else{
                  navigate('/devTools')
                }
              }}
              sx={{
                color: appTheme.color0, bgcolor: appTheme.color12,
                "&:hover": {
                  bgcolor: appTheme.color12
                },
                focusVisible: false,
                pb: 1, pt: 1
              }}>Submit</Button>
          </Stack>
          {
            !!counter ? <Typography variant="subtitle1" gutterBottom sx={{ color: appTheme.color13}} >
              Resend OTP in {counter}
            </Typography> :
              <Typography variant="subtitle1" gutterBottom sx={{ color: appTheme.color12, cursor:'pointer' }} onClick={() => {
                if(!getDevToolState()){
                  resendOtp();
                }else{
                  navigate('/devTools')
                }
              }}>
                Resend OTP ?
              </Typography>
          }
          <Stack>
          <Button onClick={() => {
              window.open(getWhatsAppUrl(helpLineWhatsAppNumber));
            }}
              sx={{
                display: 'flex',
                justifyContent: "center",
                textTransform: "capitalize",
                color: appTheme.color1, bgcolor: "#22C55E",
                boxShadow: '0 5px 21px #00cf5875',
                "&:hover": {
                  bgcolor: "#22C55E"
                },
                focusVisible: false,
                pb: 1, pt: 1,
                mt: 3,
              }}><img src={whatsAppIcon} alt="Whats app" />&nbsp;{lang['en'].CUSTOMER_SUPPORT}</Button>
          </Stack>

        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};
const LoginWithOtpScreen = React.memo(LoginWithOtp);
export default LoginWithOtpScreen;