import { Box, CssBaseline, Skeleton, ThemeProvider } from "@mui/material";
import React, { useCallback, useState } from "react";
import PassbookCard from "../../layouts/PassbookCard";
import theme from "../../theme/theme";
import { Container } from "@mui/system";
import axiosService from "../../axios-api-kit/axiosService";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useLocation, useNavigate } from "react-router-dom";
import SpeedDialComponent from "../../layouts/SpeedDial";
import { speedDialActionKey } from "../../constants/SpeedDialKey";
import { getWhatsAppUrl, passbookDataHelper } from "../../constants/Helper";
import { useSelector } from "react-redux";
import PaymentVideo from "../../layouts/PaymentVideo";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactComponent as SupportIcon } from '../../img/help-desk.svg';

const speedDialActions = [
  { icon: <WhatsAppIcon />, name: 'Customer support?', action: speedDialActionKey.CUSTOMERSUPPORT },
  { icon: <WhatsAppIcon />, name: 'Deposit issue?', action: speedDialActionKey.DEPOSITISSUE },
  { icon: <WhatsAppIcon />, name: 'Withdraw issue?', action:speedDialActionKey.WITHDRAWISSUE },
  { icon: <OndemandVideoIcon />, name: 'How to pay video?', action: speedDialActionKey.HOWTOPAY },
];
const actionIconSize = 40;
const Passbook = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [transactionsData, setTransactionsData] = useState(null);
  const [openPaymentVideo, setOpenPaymentVideo] = React.useState(false);
  const { deposit_number, withdraw_number } = useSelector(store => store?.app?.paymentHelplineData)
  const { content } = useSelector(store => store?.app?.commonHelpline);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(null);
  const [totalNumberOfData, setTotalNumberOfData] = useState(null);

  const getTransactionData = useCallback(() => {
    axiosService.getTransactionDetails(pageNumber).then((response) => {
      setPerPage(response?.data?.per_page);
      const totalData = response?.data?.total;
      setTotalNumberOfData(totalData);
      if(totalData > 9){
        sessionStorage.setItem(localStorageKey.SHOULDOPENREFERMODAL, JSON.stringify(true));
      }
      const transactionsData = response?.data?.data || response?.data || [];
      setTransactionsData((prvState) => !!prvState ? [...prvState, ...passbookDataHelper(transactionsData)]: passbookDataHelper(transactionsData));
    }).catch(error => {
    })
  },[pageNumber])
  const closeVideo = () => {
    setOpenPaymentVideo(false);
    localStorage.setItem(localStorageKey.USER_LOGGED_IN_TIME, new Date());
  }


  const openSppedDialAction = (action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case speedDialActionKey.CUSTOMERSUPPORT:
        window.open(getWhatsAppUrl(content));
       break;
      case speedDialActionKey.DEPOSITISSUE:
        window.open(getWhatsAppUrl(deposit_number));
       break;
      case speedDialActionKey.WITHDRAWISSUE:
        window.open(getWhatsAppUrl(withdraw_number));
       break;
      case speedDialActionKey.HOWTOPAY:
       if(!openPaymentVideo){
         setOpenPaymentVideo(true)
       }
       break;
    }
   }
  React.useEffect(() => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      sessionStorage.removeItem(localStorageKey.SETIDSTABSTATE);
      getTransactionData();
    }
    

  }, [pageNumber])
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          {transactionsData !== null ? <PassbookCard transactionsData={transactionsData} setPageNumber={setPageNumber} perPage={perPage} 
          totalNumberOfData={totalNumberOfData}/> : 
          <Box sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        </Box>
          }
        </Container>
        {
            <SpeedDialComponent cbSpeedial={openSppedDialAction} direction="up" 
            position={{ position: 'fixed', bottom: 80, right: 15 }} 
            actions={speedDialActions}
            actionIcon={<SupportIcon style={{ width: actionIconSize, height: actionIconSize }} />}
            actionIconSize={actionIconSize} />
        }
        {
        openPaymentVideo && <PaymentVideo closeVideo={closeVideo} />
      }
      </ThemeProvider>
    </React.Fragment>
  );
};


export default Passbook;
