import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
    name:'app',
    initialState:{
        commonInfo:{},
        userInfo: {},
        helpLineWhatsAppNumber:'',
        commonHelpline:{},
        withdrawalStatus:{},
        newsData:{},
        paymentHelplineData:{},
        isAppDownloadSnackbarOpen:false,
        myIdData:null,
    },
    reducers:{
        setCommonInfo:(state,action) => {
            state.commonInfo = {...state.commonInfo, ...action.payload}
        },
        setUserInfo:(state, action) => {
            state.userInfo = {...state.userInfo, ...action.payload};
        },
        setHelpLineWhatsAppNumber:(state, action) => {
            state.helpLineWhatsAppNumber = action.payload;
        },
        setCommonHelpline:(state, action) => {
            state.commonHelpline = {...state.commonHelpline, ...action.payload};
        },
        setWithdrawalStatus:(state, action) => {
            state.withdrawalStatus = {...state.withdrawalStatus, ...action.payload};
        },
        setNewsData:(state, action) => {
            state.newsData = {...state.newsData, ...action.payload};
        },
        setPaymentHelplineData:(state, action) => {
            state.paymentHelplineData = {...state.paymentHelplineData, ...action.payload};
        },
        setIsAppDownloadSnackbarOpen:(state) => {
            state.isAppDownloadSnackbarOpen = !state.isAppDownloadSnackbarOpen
        },
        setMyIdData:(state, action) => {
            state.myIdData = action.payload;
        }

    }
});

export const { setCommonInfo, setUserInfo, setHelpLineWhatsAppNumber, setCommonHelpline, setWithdrawalStatus, setNewsData, setPaymentHelplineData, setIsAppDownloadSnackbarOpen, setMyIdData } = appSlice.actions;
export default appSlice.reducer;