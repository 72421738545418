import * as React from "react";

import Radio from '@mui/material/Radio';
import { appTheme } from "../../theme/colorTheme";

const RadioButtonInput = (props) => {
  return (
    <Radio
      disabled={props.disabled}
      checked={props.selectedValue === props.value}
      onChange={props.handleChange}
      value={props.value}
      name="radio-buttons"
      inputProps={{ 'aria-label': props.value }}
      sx={{
        color: appTheme.color9,
        "&.Mui-checked": {
          color: appTheme.color12,
        },
        "&.Mui-disabled": {
          color: appTheme.color10,
        },
      }}
    />

  );
};

const RadioButton = React.memo(RadioButtonInput);
export default RadioButton;
