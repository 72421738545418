import React from "react";
import Dialog from "@mui/material/Dialog";

import Typography from "@mui/material/Typography";
import { CssBaseline, ThemeProvider, Container } from "@mui/material";
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
import { appTheme } from "../../theme/colorTheme";
import { AuthContext } from "../../context/AuthProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DevtoolsScreen = () => {
    const {getDevToolState } = React.useContext(AuthContext);
    let navigate = useNavigate();
    React.useEffect(() => {
        if (!getDevToolState()) {
            navigate(-1)
        }
    }, [navigate, getDevToolState])

    return (
        <>
            <Dialog
                fullScreen
                open={true}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <DialogContent sx={{ backgroundColor: appTheme.color4, padding: 0, pb: 1, }}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container sx={{
                            color: appTheme.color1,
                            mt: 2
                        }}>
                            <Typography variant="caption" component="div" color={appTheme.color1} >
                                Close the devtools and Refresh the page
                            </Typography>
                        </Container></ThemeProvider>

                </DialogContent>
            </Dialog>
        </>
    );
};
const DevtoolsScreenDailog = React.memo(DevtoolsScreen);
export default DevtoolsScreenDailog;
