import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from './components/home/home';
import Offers from './components/offers/offers';
import Ids from './components/ids/ids';
import Passbook from './components/passbook/passbook'
import LoginScreen from "./components/login/login";
import RequireAuth from "./requireAuth";
import { localStorageKey } from "./constants/LocalStorageKey";
import WalletDepositDailog from "./components/walletDeposit/walletDepositDailog";
import WithdrawlDetailsDialog from "./components/withdrawalDetail/withdrawlDetailsDailog";
import CreateIdDailog from "./components/createId/createIdDailog";
import PaymentDailog from "./components/payment/paymentDailog";
import IdDepositDailog from "./components/idDeposit/idDepositDailog";
import TransactionDetailDailog from "./components/transaction/transactionDetailDailog";
import WithdrawIdDailog from "./components/withdrawId/withdrawIdDailog";
import WithdrawWalletDailog from "./components/withdrawWallet/withdrawWalletDialog";
import NotificationsDailog from "./components/notifications/notifications";
import { AuthContext } from "./context/AuthProvider";
import TermsConditionsDailog from "./components/termsConditions/termsConditions";
import LandingPage from "./components/landingPage/landingPage";
import CookiesDailog from "./components/cookies/cookies";
import PrivacyPolicyDailog from "./components/privacyPolicy/privacyPolicy";
import DevtoolsScreenDailog from "./components/devToolsComponent/devToolsComponent";
import RegisterScreen from "./components/signup/register";
import TransactionStatus from "./components/transactionStatus/transactionStatus";
import NotFound from "./components/notFound/notFound";
import PaymentGatewayCheckout from "./components/paymentGatewayCheckout/paymentGatewayCheckout";

const RoutesApp = () => {
  const location = useLocation();
  const { setCurrentPageName, getDevToolState } = React.useContext(AuthContext);
  React.useEffect(() => {
    setCurrentPageName();
  }, [location.key])
  return (
    <Routes>
      <Route path="devTools" element={<DevtoolsScreenDailog />} />
      <Route path="*" element={<NotFound />} />
      {getDevToolState() ? <Route path="/home" element={<Navigate to='/devTools' />} /> : <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/offers" element={<Navigate to='/devTools' />} /> : <Route path="/offers" element={<RequireAuth><Offers /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/ids" element={<Navigate to='/devTools' />} /> : <Route path="/ids" element={<RequireAuth><Ids /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/passbook" element={<Navigate to='/devTools' />} /> : <Route path="/passbook" element={<RequireAuth><Passbook /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/walletDeposit" element={<Navigate to='/devTools' />} /> : <Route path="/walletDeposit" element={<RequireAuth><WalletDepositDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/withdrawDetails" element={<Navigate to='/devTools' />} /> : <Route path="/withdrawDetails" element={<RequireAuth><WithdrawlDetailsDialog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/createId" element={<Navigate to='/devTools' />} /> : <Route path="/createId" element={<RequireAuth><CreateIdDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/payment" element={<Navigate to='/devTools' />} /> : <Route path="/payment" element={<RequireAuth><PaymentDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/depositId" element={<Navigate to='/devTools' />} /> : <Route path="/depositId" element={<RequireAuth><IdDepositDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/withdrawId" element={<Navigate to='/devTools' />} /> : <Route path="/withdrawId" element={<RequireAuth><WithdrawIdDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/withdrawWallet" element={<Navigate to='/devTools' />} /> : <Route path="/withdrawWallet" element={<RequireAuth><WithdrawWalletDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/transactionsDetails" element={<Navigate to='/devTools' />} /> : <Route path="/transactionsDetails" element={<RequireAuth><TransactionDetailDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/notifications" element={<Navigate to='/devTools' />} /> : <Route path="/notifications" element={<RequireAuth><NotificationsDailog /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/termsNconditions" element={<Navigate to='/devTools' />} /> : <Route path="/termsNconditions" element={<TermsConditionsDailog />} />}
      {getDevToolState() ? <Route path="/cookiesPolicy" element={<Navigate to='/devTools' />} /> : <Route path="/cookiesPolicy" element={<CookiesDailog />} />}
      {getDevToolState() ? <Route path="/privacyPolicy" element={<Navigate to='/devTools' />} /> : <Route path="/privacyPolicy" element={<PrivacyPolicyDailog />} />}
      {getDevToolState() ? <Route path="/transactionStatus" element={<Navigate to='/devTools' />} /> : <Route path="/transactionStatus" element={<RequireAuth><TransactionStatus /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/paymentGatewayCheckout" element={<Navigate to='/devTools' />} /> : <Route path="/paymentGatewayCheckout" element={<RequireAuth><PaymentGatewayCheckout /></RequireAuth>} />}
      {getDevToolState() ? <Route path="/" element={<Navigate to='/devTools' />} /> :
        !!localStorage.getItem(localStorageKey.APITOKEN) ? <Route path='/' element={<Navigate to='/home' />} /> : <Route path='/' element={<LandingPage />} />}
      {getDevToolState() ? <Route path="/login" element={<Navigate to='/devTools' />} /> :
        !!localStorage.getItem(localStorageKey.APITOKEN) ? <Route path='/login' element={<Navigate to='/home' />} /> : <Route path='/login' element={<LoginScreen />} />
      }
      {getDevToolState() ? <Route path="/register" element={<Navigate to='/devTools' />} /> :
        !!localStorage.getItem(localStorageKey.APITOKEN) ? <Route path='/signup' element={<Navigate to='/home' />} /> : <Route path='/signup' element={<RegisterScreen />} />
      }
    </Routes>
  );
};

export default RoutesApp;