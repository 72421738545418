import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Divider, Typography } from '@mui/material';
import AddAccountFormScreen from './AddBankAccountForm';
import UpiDetailsFormScreen from './AddUpiDetailsFrom';
import { useNavigate } from 'react-router-dom';
import { appTheme } from '../theme/colorTheme';
import { phoneNumberValidation, upiIdRegexValidation } from '../constants/Helper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const AddPaymentsDailogPopup = (props) => {
    const phoneNumberValidationFn = React.useCallback(phoneNumberValidation,[])
    const upiIdRegexValidationFn = React.useCallback(upiIdRegexValidation,[])
    const paymentType = props.selectedPayment.type.toLocaleLowerCase() === 'upi'? 'Upi Id':`number`
    let navigate = useNavigate();
    const handleClose = () => {
        props.setOpenPaymentDialog(false)
    }

    const getData = () => {
        props.getData();
    }

    return (
        <>
            <Dialog
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: appTheme.color3,
                        width: '500px',
                        position: 'relative'
                    }
                }}
                open={props.openPaymentDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <ClearRoundedIcon
                    onClick={() => {
                        if (!!props.isFromWithdraw) {
                            navigate('/')
                        } else {
                            handleClose();
                        }

                    }}
                    fontSize='small' fontWeight='800'
                    sx={{ color: appTheme.color0, cursor: "pointer", borderRadius: 100, bgcolor: appTheme.color6, position: 'absolute', right: 10, top: 12 }} />
                <DialogTitle sx={{ color: appTheme.color1, textAlign: 'left', padding: 1.5 }}>
                    {
                        props?.selectedPayment?.type.toLocaleLowerCase() === 'bank' ? <Typography variant="body1" display="block">
                            {
                                props?.isBankDetailsUpdate ? 'Update' : 'Add New'
                            } {props?.selectedPayment?.name}
                        </Typography> :
                            <Typography variant="body1" display="block">
                                Add New {props?.selectedPayment?.name} {paymentType}
                            </Typography>
                    }
                    {
                        !!props.isFromWithdraw ? <Typography variant="caption" display="block" sx={{ color: appTheme.color12 }}>
                            Adding Bank Details is mandatory for processing withdrawals.
                        </Typography> : ''
                    }

                </DialogTitle>
                <Divider sx={{ borderTop: `1px solid ${appTheme.color12}` }} />
                <DialogContent>
                    {props.openPaymentDialog ?
                        props.selectedPayment.type.toLocaleLowerCase() === 'bank' ? <AddAccountFormScreen
                            handleClose={handleClose}
                            getData={getData}
                            isBankDetailsUpdate={props.isBankDetailsUpdate}
                            selectedAccount={props.selectedPayment} /> : <UpiDetailsFormScreen
                            selectedAccount={props.selectedPayment}
                            paymentTypeValidation={props.selectedPayment.type.toLocaleLowerCase() === 'upi'? upiIdRegexValidationFn:phoneNumberValidationFn}
                            handleClose={handleClose}
                            getData={getData} />
                        : ''}
                </DialogContent>
            </Dialog>
        </>
    );
}
const AddPaymentsDailog = React.memo(AddPaymentsDailogPopup)
export default AddPaymentsDailog;
