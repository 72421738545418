import React from "react";
import {
    ThemeProvider,
    CssBaseline,
    Button,
    Stack,
    Typography
} from "@mui/material";
import { Container } from "@mui/system";
import { nameValidation, passwordValidation, phoneNumberValidation } from "../../constants/Helper";
import theme from "../../theme/theme";
import CustomizedInputs from "../customComponent/CustomInput";
import FullScreenDialog from "../../layouts/FullScreenDailog";
import InputAdornment from '@mui/material/InputAdornment';
import LoginWithOtpScreen from "../login/loginWithOtp";
import axiosService from "../../axios-api-kit/axiosService";
import CheckboxLabels from "../customComponent/Checkbox";
import LoginTermsConditionsDailog from "../login/termsNcondition";
import { appTheme } from "../../theme/colorTheme";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import { localStorageKey } from "../../constants/LocalStorageKey";

const Register = () => {
    let navigate = useNavigate();
    const userNumber = sessionStorage.getItem(localStorageKey.USERNUMBER);
    const [userName, setUserName] = React.useState("User");
    const [isUserNameValid, setIsUserNameValid] = React.useState(true);
    const [phoneNumber, setPhoneNumber] = React.useState(userNumber);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [password, setPassword] = React.useState("");
    const [isPasswordValid, setIsPasswordValid] = React.useState(true);

    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = React.useState(true);

    const [isTermsNConditionChecked, setIsTermsNConditionChecked] = React.useState(false);

    const [openTermsDialog, setOpenTermsDialog] = React.useState(false);
    const {getDevToolState } = React.useContext(AuthContext);

    const isFormValid = (isPhoneNumberValidCheck, phoneNumberCheck, isPasswordValidCheck,
        passwordCheck, isConfirmPasswordValidCheck, confirmPasswordCheck) => {
        if (isPhoneNumberValidCheck &&
            phoneNumberCheck.length !== 0 &&
            isPasswordValidCheck &&
            passwordCheck.length !== 0 &&
            isConfirmPasswordValidCheck &&
            confirmPasswordCheck.length !== 0
        ) {
            const payloadData = new URLSearchParams()
            payloadData.append('phone', phoneNumber);
            payloadData.append('event', 'signup-otp');
            axiosService.sendAuthOtp(payloadData).then((resp) => {
                loginWithOtp();
            }).catch((error) => {
            })

        }

    };
    const openLoginWithOtp = React.useCallback((inputData) => {
        return <LoginWithOtpScreen data={inputData} />
    }, [])

    const checkUserNameValidation = React.useCallback((value) => {
        if (nameValidation(value)) {
            setIsUserNameValid(true)
        } else {
            setIsUserNameValid(false)
        }
    }, [])

    const checkPhoneNumberValidation = React.useCallback((value) => {
        if (phoneNumberValidation(value)) {
            setIsPhoneNumberValid(true)
        } else {
            setIsPhoneNumberValid(false)
        }
    }, [])

    const checkPasswordValidation = React.useCallback((value) => {
        if (passwordValidation(value)) {
            setIsPasswordValid(true)
        } else {
            setIsPasswordValid(false)
        }
    }, [])

    const checkConfirmPasswordValidation = React.useCallback((value) => {
        if (passwordValidation(value) && value === password) {
            setIsConfirmPasswordValid(true)
        } else {
            setIsConfirmPasswordValid(false)
        }
    }, [password])

    const submitLogin = (userNameCheck, phoneNumberCheck, passwordCheck, confirmPasswordCheck) => {
        checkUserNameValidation(userNameCheck);
        checkPhoneNumberValidation(phoneNumberCheck);
        checkPasswordValidation(passwordCheck)
        checkConfirmPasswordValidation(confirmPasswordCheck)
        isFormValid(isPhoneNumberValid, phoneNumberCheck, isPasswordValid, passwordCheck, isConfirmPasswordValid, confirmPasswordCheck);
    }


    const loginWithOtp = () => {
        setOpenDialog(true);
    }

    React.useEffect(() => {
      
    }, [])


    return (
        <React.Fragment>
            {!!openDialog ? <FullScreenDialog openDialog={openDialog} setOpenDialog={setOpenDialog} headerText={'Enter Verification Code'} layout={() => openLoginWithOtp({
                name: userName,
                enteredMobileNumber: phoneNumber,
                password: password
            }
            )} /> : ''}
            {!!openTermsDialog ? <LoginTermsConditionsDailog openTermsDialog={openTermsDialog} setOpenTermsDialog={setOpenTermsDialog} /> : ''}
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    sx={{
                        color: appTheme.color1,
                        mt: 7
                    }}
                > 
                <Typography sx={{mb:2}} variant="body1" component="div">
                            Sign Up
                        </Typography>
                <form autoComplete="new-password">
                    <CustomizedInputs key={"2"} placeholder="Enter Phone Number"
                        label="Phone Number*" defaultValue={phoneNumber}
                        type="number"
                        setData={setPhoneNumber}
                        isValidation={isPhoneNumberValid}
                        checkValidation={checkPhoneNumberValidation}
                        startAdornment={
                            <InputAdornment position="start" sx={{ color: appTheme.color1 }}>
                                <img
                                    src={require("../../img/icons8-india-48.png")}
                                    width="30"
                                    alt="logo"
                                    loading="lazy"
                                />&nbsp;+91
                            </InputAdornment>
                        } />

                    <CustomizedInputs key={"3"} placeholder="Enter Password"
                        label="Password*" defaultValue={password}
                        type="password"
                        setData={setPassword}
                        isValidation={isPasswordValid}
                        checkValidation={checkPasswordValidation} />

                    <CustomizedInputs key={"4"} placeholder="Confirm Password"
                        label="Confirm Password*" defaultValue={confirmPassword}
                        type="password"
                        setData={setConfirmPassword}
                        isValidation={isConfirmPasswordValid}
                        checkValidation={checkConfirmPasswordValidation} />

                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="left"
                        sx={{ mb: 2 }}
                    >
                        <CheckboxLabels onChange={(e, newValue) => {
                            setIsTermsNConditionChecked(newValue);
                        }} />
                        <Typography
                            variant="caption"
                            display="block"
                            sx={{ color: `white`, ml: 1 }}
                        >
                            I've read and accept the <span onClick={() => {
                                setOpenTermsDialog(true)
                            }} style={{ color: appTheme.color14, cursor: 'pointer', textDecoration: 'none' }}>
                                Terms & Conditions
                            </span>
                        </Typography>
                    </Stack>

                    <Stack spacing={2}>
                        <Button disabled={!isTermsNConditionChecked} onClick={() => {
                            if(!getDevToolState()){
                                submitLogin(userName, phoneNumber, password, confirmPassword);
                              }else{
                                navigate('/devTools')
                              }
                        }}
                            sx={{
                                color: appTheme.color0, bgcolor: appTheme.color12,
                                "&:hover": {
                                    bgcolor: appTheme.color12
                                },
                                focusVisible: false,
                                pb: 1, pt: 1
                            }}>Submit</Button>

                    </Stack>

                </form>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
};

const RegisterScreen = React.memo(Register);
export default RegisterScreen;
