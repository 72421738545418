import React, { createRef, forwardRef, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/system";
import { dateConvert } from "../constants/Helper";
import NotificationsDetail from "./NotificationsDetails";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import NoData from "./NoData";
import { appTheme } from "../theme/colorTheme";



const NotificationsList = forwardRef(({ id, item, onChange, viewTransactionDetails, expanded, servicesRef }, ref) => (
  <Accordion
    id={id}
    ref={ref}
    key={item.id}
    expanded={expanded === `${item?.id}panel`}
    onChange={onChange(`${item?.id}panel`)}
    sx={{ bgcolor: appTheme.color11, mb: 1, padding: 0, borderRadius: 2 }}
    disableGutters={true}
    square={true}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ color: appTheme.color1 }} />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ padding: 0, pl: 1, pr: 1, width: "100%" }}
    >
      <Stack
        sx={{ color: appTheme.color1, width: '100%' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <ListItem sx={{ padding: 0, width: "100%" }}>
            <ListItemAvatar sx={{ minWidth: '30px' }}>
              {
                item?.transaction?.status.toLocaleLowerCase() === 'rejected' ?
                  <CancelRoundedIcon color="error" /> : item?.transaction?.status.toLocaleLowerCase() === 'pending' ?
                    <WarningRoundedIcon color="warning" /> : <CheckCircleRoundedIcon color="success" />
              }
            </ListItemAvatar>
            <ListItemText sx={{ color: appTheme.color1, padding: 0 }} primary={
              <Typography component="div" variant="caption" display="block" gutterBottom fontSize={11}>
                {item?.message}
              </Typography>

            } secondary={
              <Typography
                display="block"
                component="span"
                variant="caption"
                color={appTheme.color6}
                fontSize={10}
              >
                {dateConvert(item?.transaction?.created_at)}
              </Typography>
            } />
          </ListItem>
        </Box>
      </Stack>
    </AccordionSummary>
    <AccordionDetails ref={servicesRef}>
      <NotificationsDetail itemList={item} viewTransactionDetails={viewTransactionDetails} />
    </AccordionDetails>
  </Accordion>
));

const NotificationsAccordianScreen = ({notificationsData, viewTransactionDetails, totalNumberOfData, perPage, setPageNumber}) => {
  const servicesRef = useRef(null);
  const sentinel = createRef();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
     if(entry.isIntersecting && notificationsData.length >= perPage && notificationsData.length < totalNumberOfData){
      observer.disconnect();
      sentinel.current = null;
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
    })
  }, options);

  useEffect(() => {
    !!notificationsData && notificationsData.length !== 0 && setExpanded(`${notificationsData[0].id}panel`)
    if(notificationsData.length > 0 && notificationsData.length >= perPage && notificationsData.length < totalNumberOfData){
      observer.observe(sentinel.current);
    }
    return () => {
      observer.disconnect();
    }

  }, [notificationsData.length])

  return (
    <React.Fragment>
      {
        !!notificationsData && notificationsData.length !== 0 ?
          notificationsData.map((item, i) => {
            if (i === notificationsData.length - 2) {
              return <NotificationsList
                id={item.id}
                ref={sentinel}
                item={item}
                key={item.id}
                onChange={handleChange}
                viewTransactionDetails={viewTransactionDetails}
                expanded={expanded}
                servicesRef={servicesRef}
              />
            }
            return <NotificationsList
              id={item.id}
              item={item}
              key={item.id}
              onChange={handleChange}
              viewTransactionDetails={viewTransactionDetails}
              expanded={expanded}
              servicesRef={servicesRef}
            />
          }) : <NoData noDataText="You don't have any notifications yet." />
      }
    </React.Fragment>
  );
};

const NotificationsAccordian = React.memo(NotificationsAccordianScreen)

export default NotificationsAccordian;
