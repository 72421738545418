import React from "react";
import {
  ThemeProvider,
  CssBaseline,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../../theme/theme";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
const Offers = () => {
  let navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      sessionStorage.removeItem(localStorageKey.SETIDSTABSTATE)
    }
    
  }, [])
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: appTheme.color1,
            height: "80vh",
          }}
        >
          <Box>
            <Stack
              sx={{
                ml: 2,
                width: 86,
                height: 86,
                borderRadius: 100,
                backgroundColor: appTheme.color7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <img
                src={require("../../img/noOffer.png")}
                alt="noOffer"
                loading="lazy"
              />
            </Stack>
            <Typography variant="h6" gutterBottom component="div">
              No Offers
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              Please create ID
            </Typography>
            <Button
              variant="contained"
              color="inherit"
              startIcon={<AddCircleSharpIcon />}
              onClick={() => {
                navigate('/ids?index=1')
              }}
              sx={{
                bgcolor: appTheme.color12,
                "&:hover": {
                  bgcolor: appTheme.color12
                },
                color: appTheme.color0,
                mt: 1,
                focusVisible: false,
              }}
            >
              Create ID
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Offers;
