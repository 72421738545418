import * as React from 'react';
import CheckboxLabels from '../components/customComponent/Checkbox';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { appTheme } from '../theme/colorTheme';
import { getImgSrc } from '../constants/Helper';

const PaymentModeScreen = (props) => {
  const [selectedPaymentType, setSelectedPaymentType] = React.useState([]);
  return (
    <>
      {
        props.data.map((item) => (
          <Box key={item.id} sx={{ flexGrow: 1, backgroundColor: appTheme.color11, mb: 1.5, pb: 0.5, borderRadius: 2 }}>
            <Stack sx={{ backgroundColor: appTheme.color4, mb: .5, pl: 1.5, pr: 1.5, pb: 1, pt: 1, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} direction="row"
              alignItems="center"
              justifyContent="space-between">
              <img
                src={!!getImgSrc(item?.logo_url) ? getImgSrc(item.logo_url) : require('../img/no-pictures.png')}
                style={{ verticalAlign: 'middle', borderRadius: 50, width: "30px", marginRight: 8 }}
                alt="logo"
                loading="lazy"
              />
              <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center', justifyContent: 'space-between', zIndex: 0 }}>
                <CheckboxLabels checked={selectedPaymentType?.id === item?.id} onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedPaymentType(item);
                    props.handleChecked(item, newValue);
                  } else {
                    setSelectedPaymentType(null)
                    props.handleChecked(null, newValue);
                  }
                }} />
                <Typography variant="caption" sx={{ fontWeight: 'bold' }} fontSize={13} color={appTheme.color12}>&nbsp;Select</Typography>
              </Box>

            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: appTheme.color1, pl: 1.5, pr: 1.5 }}
            >
              <Typography variant="caption" gutterBottom fontSize={12}>Display name</Typography>
              <Typography variant="caption" gutterBottom fontSize={12}>{item.account_display_name}</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: appTheme.color1, pl: 1.5, pr: 1.5 }}
            >
              <Typography variant="caption" gutterBottom fontSize={12}>Upi Id</Typography>
              <CopyToClipboard text={item.account_identifier}>
                <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={props.handleClick}>
                  {item.account_identifier}&nbsp;
                  <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
              </CopyToClipboard>
            </Stack>
          </Box>
        ))
      }
    </>

  )
}

const PaymentMode = React.memo(PaymentModeScreen)

export default PaymentMode;
