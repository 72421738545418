import React from "react";
import { Navigate, useLocation } from 'react-router-dom'
import { localStorageKey } from "./constants/LocalStorageKey";

const RequireAuth = ({ children }) => {
    const location = useLocation()
    if (!localStorage.getItem(localStorageKey.APITOKEN)) {
        return <Navigate to='/login' state={{ from: location.pathname }} />
    }
    return children
}

export default RequireAuth