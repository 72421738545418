import { configureStore } from "@reduxjs/toolkit";
import headerIntersectionSlice from "./headerIntersectionSlice";

import appSlice from "./appSlice";

const store = configureStore({
  reducer: {
    headerIntersection:headerIntersectionSlice,
    app:appSlice
  }
});

export default store;

