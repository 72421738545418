import { appTheme } from '../theme/colorTheme';
import constant from './Constatnt';

export const userNameValidation = name => {
  const regex = new RegExp('^[a-zA-Z0-9]{3,8}$');
  return regex.test(name);
};
export const nameValidation = name => {
  const regex = new RegExp('^[a-zA-Z0-9, ]{2,20}$');
  return regex.test(name);
};

export const phoneNumberValidation = phoneNumber => {
  const regex = new RegExp('^[0-9]{10}$');
  return regex.test(phoneNumber);
}

export const accountNumberValidation = accounNumber => {
  const regex = new RegExp('^[0-9]{9,20}$');
  return regex.test(accounNumber);
}

export const upiIdRegexValidation = upiId => {
  const regex = new RegExp('^[a-zA-Z0-9.-_]{2,49}@[a-zA-Z._]{2,49}$');
  return regex.test(upiId);
}

export const ifscCodeValidation = ifscCode => {
  const regex = new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$');
  return regex.test(ifscCode);
}

export const passwordValidation = phoneNumber => {
  const regex = new RegExp('^.{5,35}$');
  return regex.test(phoneNumber);
}

export const insertHyphens = number => {
  const numberOfDigits = number.length;
  if (numberOfDigits < 8) {
    return number
  } else if (numberOfDigits === 8) {
    return number.replace(/(\d{4})(\d{4})/, "$1-$2")
  } else if (8 < numberOfDigits && numberOfDigits < 12) {
    return number.replace(/(\d{4})(\d{4})(\d{1})/, "$1-$2-$3");
  }
  return number.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
};
export const getUpiPaymentUrl = (upiId, name, amount) => {
  return `upi://pay?pa=${upiId}&pn=${name}&am=${amount}&cu=INR`
}
export const getPonepePaymentUrl = (upiId, name, amount) => {
  return `phonepe://pay?pa=${upiId}&pn=${name}&am=${amount}&cu=INR`
}
export const getGoolePayPaymentUrl = (upiId, name, amount) => {
  return `tez://upi/pay?pa=${upiId}&pn=${name}&am=${amount}&cu=INR`
}
export const getPaytmPaymentUrl = (upiId, name, amount) => {
  return `paytmmp://pay?pa=${upiId}&pn=${name}&am=${amount}&cu=INR`
}

export const dateConvert = value => {
  if (!!value) {
    const selectedDate = new Date(value)
    const date = selectedDate.toDateString()
    const time = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(selectedDate)
    return `${date} ${time}`;
  }
  return '';
}


export const timeDifference = (firstDate, secondDate) => {
  if (!!firstDate && !!secondDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);

    const diff = date2.getTime() - date1.getTime();

    let msec = diff;
    const hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    const mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    const ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    const hrText = hh > 1 ? 'hrs' : 'hr';
    const minText = mm > 1 ? 'mins' : 'min';
    const secText = ss > 1 ? 'secs' : 'sec';

    return `${hh}${hrText} ${mm}${minText} ${ss}${secText}`
  } else {
    return '';
  }

}

export const getHoursDifference = (date1, date2) => {
  if (!!date1 && !!date2) {
    const millisecondsDifference = Math.abs(date2 - date1);
    const hoursDifference = millisecondsDifference / (1000 * 60 * 60); // 1 hour = 1000 milliseconds * 60 seconds * 60 minutes
    return hoursDifference;
  }
};


export const isImagePathExist = (imageUrl) => {
  return (imageUrl?.includes("https") || imageUrl?.includes("http")) && (
    imageUrl?.includes(".jpg") ||
    imageUrl?.includes(".png") ||
    imageUrl?.includes(".jpeg") ||
    imageUrl?.includes(".gif") ||
    imageUrl?.includes(".webp")
  )
}

export const isImageExtnExist = (imageUrl) => {
  return imageUrl?.includes(".jpg") ||
    imageUrl?.includes(".png") ||
    imageUrl?.includes(".jpeg") ||
    imageUrl?.includes(".gif") ||
    imageUrl?.includes(".webp")
}

export const getImgSrc = (imageUrl) => {
  return (isImagePathExist(imageUrl) ? `${imageUrl}` :
    isImageExtnExist(imageUrl) ? `${constant.baseUrl}${imageUrl}` :
      false
  )
}


export const isMobileOrTabDeviceType = () => {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

export const isMobileDeviceAndroid = () => {
  if (navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return false;
  } else {
    return true;
  }
}

export const downloadFile = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  const blobURL = window.URL.createObjectURL(new Blob([blob]))
  const fileName = url.split("/").pop();
  const aTag = document.createElement("a");
  aTag.href = blobURL;
  aTag.setAttribute("download", fileName);
  document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();
};

export const convertBase64toFile = (imageData) => {
  const byteCharacters = atob(imageData.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' }); // Adjust the type as needed

  // Create download link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'qrCode.png'; // Set the file name
  document.body.appendChild(a);
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export const passbookDataHelper = (passbookData) => {
  if (!passbookData.length) return [];
  return passbookData.map((item) => {
    let passbookItem = {};
    passbookItem['id'] = item.id;
    passbookItem['userId'] = item.user_id;
    passbookItem['referenceNo'] = item.transaction_number;
    passbookItem['requestedDate'] = dateConvert(item.created_at);
    //transactionRequest
    switch (item.transactionable_type.toLocaleLowerCase()) {
      case (constant.transactionType.depositWallet.toLocaleLowerCase()):
      case constant.transactionType.withdrawWallet.toLocaleLowerCase():
        passbookItem['transactionRequest'] = 'WalletRequest';
        break;
      case constant.transactionType.depositId.toLocaleLowerCase():
      case constant.transactionType.withdrawId.toLocaleLowerCase():
        passbookItem['transactionRequest'] = 'ExistingIdRequest';
        break;
      case constant.transactionType.changeIdPasswordRequest.toLocaleLowerCase():
        passbookItem['transactionRequest'] = 'ChangePasswordRequest';
        break;
      case constant.transactionType.closeIdRequest.toLocaleLowerCase():
        passbookItem['transactionRequest'] = 'CloseIdRequest';
        break;
      case constant.transactionType.createIdRequest.toLocaleLowerCase():
          passbookItem['transactionRequest'] = 'CreateIdRequest';
          break;
      default:
        passbookItem['transactionRequest'] = '';
        break;
    }

    //transactionType & amount
    switch (item.transactionable_type.toLocaleLowerCase()) {
      case constant.transactionType.withdrawWallet.toLocaleLowerCase():
      case constant.transactionType.withdrawId.toLocaleLowerCase():
        passbookItem['transactionType'] = 'Withdraw';
        passbookItem['amount'] = `-${item.transactionable.requested_amount}`;
        break;
      case constant.transactionType.depositWallet.toLocaleLowerCase():
      case constant.transactionType.depositId.toLocaleLowerCase():
        passbookItem['transactionType'] = 'Deposit';
        passbookItem['amount'] = `+${item.transactionable.requested_amount}`;
        break;
      case constant.transactionType.closeIdRequest.toLocaleLowerCase():
        passbookItem['transactionType'] = 'CloseId';
        passbookItem['amount'] = '';
        break;
      case constant.transactionType.changeIdPasswordRequest.toLocaleLowerCase():
        passbookItem['transactionType'] = 'ChangePassword';
        passbookItem['amount'] = '';
        break;
      default:
        passbookItem['transactionType'] = '';
        passbookItem['amount'] = item.transactionable.requested_amount;
        break;
    }

    //status and text color
    switch (item.status.toLocaleLowerCase()) {
      case (constant.transactionStatus.pending.toLocaleLowerCase()):
        passbookItem['status'] = 'Pending';
        passbookItem['textColor'] = appTheme.color18;
        break;
      case (constant.transactionStatus.approved.toLocaleLowerCase()):
        passbookItem['status'] = 'Approved';
        passbookItem['textColor'] = appTheme.color13;
        break;
      case (constant.transactionStatus.rejected.toLocaleLowerCase()):
        passbookItem['status'] = 'Rejected';
        passbookItem['textColor'] = appTheme.color16;
        break;

      default:
        passbookItem['status'] = item.status;
        passbookItem['textColor'] = appTheme.color18;
        break;
    }
    

    //title, logoUrl, demoId, brandName and userName
    switch (passbookItem?.transactionRequest) {
      case 'CreateIdRequest':
        passbookItem["logoUrl"] = item.transactionable.website?.logo_url;
        passbookItem["demo_id"] = item.transactionable.website?.demo_id;
        passbookItem["brandName"] = item.transactionable.website?.name;
        passbookItem['title'] = `Create ${item.transactionable.website?.name} Id`;
        passbookItem["userName"] = ''
        break;
      case 'ExistingIdRequest':
        passbookItem['title'] = '';
        passbookItem["logoUrl"] = item.transactionable.user_website?.website.logo_url;
        passbookItem["userName"] = item.transactionable.user_website?.username;
        passbookItem["demo_id"] = item.transactionable.user_website?.website.demo_id;
        passbookItem["brandName"] = item.transactionable.user_website?.website.name;
        if(passbookItem.transactionType === 'Deposit') {
          passbookItem.title = `Deposit to ${item.transactionable.user_website?.website.name} Id`;
        }else if(passbookItem.transactionType === 'Withdraw') {
          passbookItem.title = `Withdraw from ${item.transactionable.user_website?.website.name} Id`;
        }
        break;
      case 'CloseIdRequest':
          passbookItem["brandName"] = item.transactionable.user_website?.website.name;
          passbookItem['title'] = `Close ${item.transactionable.user_website?.website.name} Id`;  
          passbookItem["logoUrl"] = item.transactionable.user_website?.website.logo_url;
          passbookItem["userName"] = item.transactionable.user_website?.username;
          passbookItem["demo_id"] = '';
          break;
      case 'ChangePasswordRequest':
        passbookItem["demo_id"] = '';
        passbookItem["brandName"] = item.transactionable.user_website?.website.name;
        passbookItem["logoUrl"] = item.transactionable.user_website?.website.logo_url;
        passbookItem["userName"] = item.transactionable.user_website?.username;
        passbookItem['title'] = `Change ${item.transactionable.user_website?.website.name} password`;
        break;
      case 'WalletRequest':
        passbookItem['title'] = '';
        if (passbookItem?.transactionType === 'Deposit') {
          passbookItem.title = 'Deposit to wallet';
          passbookItem["logoUrl"] = item.transactionable?.payment_method?.logo_url;
        } else if (passbookItem?.transactionType === 'Withdraw') {
          passbookItem.title = 'Withdraw from wallet';
          passbookItem["logoUrl"] = item.user?.pref_payment_method?.logo_url;
        }
        passbookItem["demo_id"] = '';
        passbookItem["brandName"] = '';
        passbookItem["userName"] = '';
        break;
      default:
        passbookItem["logoUrl"] = '';
        passbookItem["userName"] = '';
        passbookItem["demo_id"] = '';
        passbookItem["brandName"] = '';
        passbookItem['title'] = '';
        break;
    }
    

    if (passbookItem.transactionRequest === 'CloseIdRequest') {
      passbookItem["remark"] = item.transactionable?.reason_remarks;
    }else {
      passbookItem["remark"] = item.reason_remarks;
    }
    return passbookItem;
  })
}


export const getRandomColor = (idx) => {
  let obj = {
    light: '',
    dark: ''
  };
  for (const key in appTheme.colorGradient[idx]) {
    if (!obj.light) {
      obj.light = appTheme.colorGradient[idx][key];

    } else if (!obj.dark) {
      obj.dark = appTheme.colorGradient[idx][key];
    }
  }
  return obj;
}

export const disableScroll = () => {
  // Get the current page scroll position
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  // if any scroll is attempted,
  // set this to the previous value
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

export const enableScroll = () => {
  window.onscroll = function () { };
}

export const getWhatsAppUrl = (number) => {
  const whatsAppNumberWithoutSpecialChar = number?.replace(/[^\d]/g, "");
  return `https://api.whatsapp.com/send/?phone=${whatsAppNumberWithoutSpecialChar}&text&type=phone_number&app_absent=0`
}

export const getRefreralWhatsAppUrl = () => {
  const text = `Hi! I am sharing an exclusive invite to play and win. India's biggest exchange: ${window.location.origin}`;
  return `https://api.whatsapp.com/send/?phone&text=${text}&type=phone_number&app_absent=0`
}