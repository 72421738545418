import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CheckboxLabels from '../components/customComponent/Checkbox';
import { appTheme } from '../theme/colorTheme';
import { insertHyphens } from '../constants/Helper';

const BankAccountPaymentScreen = (props) => {
  const [selectedPaymentType, setSelectedPaymentType] = React.useState([])
  return (
    <React.Fragment>

      {
        props.data.map((item, i) => (
          <Box key={i} sx={{ flexGrow: 1, backgroundColor: appTheme.color11, mb: 1.5, borderRadius: 2 }}>
            <Stack sx={{ backgroundColor: appTheme.color4, mb: .5, pl: 1.5, pr: 1.5, pb: .3, pt: .3, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Typography variant="caption" gutterBottom fontSize={12} color={appTheme.color1}></Typography>
              <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center', justifyContent: 'space-between', zIndex:0 }}>
                <CheckboxLabels checked={selectedPaymentType?.id === item?.id} onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedPaymentType(item);
                    props.handleChecked(item, newValue);
                  } else {
                    setSelectedPaymentType(null)
                    props.handleChecked(null, newValue);
                  }
                }} />
                <Typography variant="caption" sx={{ fontWeight: 'bold' }} fontSize={13} color={appTheme.color12}>&nbsp;Select</Typography>
              </Box>

            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: appTheme.color1, pl: 1.5, pr: 1.5 }}
            >
              <Typography variant="caption" gutterBottom fontSize={11}>Bank Name</Typography>
              <Typography variant="caption" gutterBottom fontSize={11}>{item.bank_name}</Typography>

            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: appTheme.color1, pl: 1.5, pr: 1.5 }}
            >
              <Typography variant="caption" gutterBottom fontSize={11}>Account Number</Typography>
              <CopyToClipboard text={item.account_identifier}>
                <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 11 }} onClick={props.handleClick}>
                  {insertHyphens(item.account_identifier)}&nbsp;
                  <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
              </CopyToClipboard>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: appTheme.color1, pl: 1.5, pr: 1.5 }}
            >
              <Typography variant="caption" gutterBottom fontSize={11}>IFSC Code</Typography>
              <CopyToClipboard text={item.ifsc_code}>
                <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 11 }} onClick={props.handleClick}>
                  {item.ifsc_code}&nbsp;
                  <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
              </CopyToClipboard>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: appTheme.color1, pl: 1.5, pr: 1.5 }}
            >
              <Typography variant="caption" gutterBottom fontSize={11}>Account holder name</Typography>
              <Typography variant="caption" gutterBottom fontSize={11}>{item.account_display_name}</Typography>
            </Stack>



          </Box>
        ))
      }

    </React.Fragment>
  );
}

const BankAccountPayment = React.memo(BankAccountPaymentScreen);

export default BankAccountPayment;
