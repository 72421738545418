import React from "react";
import { Typography } from "@mui/material";
import { Container, Box } from "@mui/system";
import { appTheme } from "../theme/colorTheme";
const NoData = (props) => {
  return (

    <>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: appTheme.color1,
          height: "60vh",
        }}
      >
        <Box>

          <img
            width="80px"
            src={require("../img/empty-box-1.png")}
            alt="noOffer"
            loading="lazy"
          />
          <Typography variant="caption" display="block" gutterBottom>
            {props.noDataText}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default NoData;
