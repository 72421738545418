import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { appTheme } from '../theme/colorTheme';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import constant from '../constants/Constatnt';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 150,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '2px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 11,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MenuConfiguration = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selectedItem) => {
    if(selectedItem.value === 'Change Password'){
      if(!props.isChangePasswordUnderMaintenance){
        props.selectedId(constant.transactionType.changeIdPasswordRequest);
      }else{
        props.handleClick('Site Under Maintenance!')
      }
    }
    if(selectedItem.value === 'Close ID'){
      if(!props.isCloseIdUnderMaintenance){
        props.selectedId(constant.transactionType.closeIdRequest);
      }else{
        props.handleClick('Site Under Maintenance!')
      }
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{ color: appTheme.color1}}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <StyledMenu
        style={{ margin: 0, padding: 0 }}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuData.map((item, i) => (
          <MenuItem  onClick={() => {
            handleClose(item)
          }} key={`${i}`} sx={{ fontSize: 11, opacity:(item.value === 'Change Password' && props.isChangePasswordUnderMaintenance)?'0.5':'1' 
          }} disableRipple>
            {
              (item.value === 'Change Password' && props.isChangePasswordUnderMaintenance) ? <>
              {item.value}&nbsp;
              <ConstructionOutlinedIcon/>
              </>: item.value
            }
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}

export default MenuConfiguration;

const menuData = [
  // {
  //   value: "View Txns/Balance"
  // },
  {
    value: "Change Password"
  },
  {
    value: "Close ID"
  }
]
