import React from "react";
import {
  ThemeProvider,
  CssBaseline,
  Typography,
  Button,
  Stack
} from "@mui/material";
import { Container } from "@mui/system";
import { passwordValidation, phoneNumberValidation } from "../../constants/Helper";
import theme from "../../theme/theme";
import CustomizedInputs from "../customComponent/CustomInput";
import FullScreenDialog from "../../layouts/FullScreenDailog";
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import LoginWithOtpScreen from "./loginWithOtp";
import axiosService from "../../axios-api-kit/axiosService";
import { AuthContext } from "../../context/AuthProvider";
import { appTheme } from "../../theme/colorTheme";
import { useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";

const LoginWithPassword = (props) => {
  const userId = sessionStorage.getItem(localStorageKey.USERID)
  let navigate = useNavigate();
  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));
  const { signIn, getDevToolState } = React.useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);


  const isFormValid = (isPhoneNumberValidCheck, phoneNumberCheck, isPasswordValidCheck, passwordCheck) => {
    if (isPhoneNumberValidCheck && phoneNumberCheck.length !== 0 && isPasswordValidCheck && passwordCheck.length !== 0) {
      const payloadData = new URLSearchParams()
      payloadData.append('phone', phoneNumber)
      payloadData.append('password', password)
      !!localStorage.getItem(localStorageKey.PUSHID)? payloadData.append('app_push_id', localStorage.getItem(localStorageKey.PUSHID)):payloadData.append('web_push_id', userId)
      axiosService.login(payloadData).then((resp) => {
        const userData = {
          api_token: resp?.data?.api_token,
          wallet_balance: resp?.data?.wallet_balance || 0,
          name: resp?.data?.name,
          phone: resp?.data?.phone,
          avatar: resp?.data?.avatar,
          id: resp?.data?.id
        }
        signIn(userData)
      }).catch((error) => {
      })
    }

  };
  const openLoginWithOtp = React.useCallback((inputData) => {
    return <LoginWithOtpScreen data={inputData} />
  }, [])

  const checkPhoneNumberValidation = (value) => {
    if (phoneNumberValidation(value)) {
      setIsPhoneNumberValid(true)
    } else {
      setIsPhoneNumberValid(false)
    }
  }

  const checkPasswordValidation = React.useCallback((value) => {
    if (passwordValidation(value)) {
      setIsPasswordValid(true)
    } else {
      setIsPasswordValid(false)
    }
  }, [])

  const submitLogin = (phoneNumberCheck, passwordCheck) => {
    checkPhoneNumberValidation(phoneNumberCheck);
    checkPasswordValidation(passwordCheck)
    isFormValid(isPhoneNumberValid, phoneNumberCheck, isPasswordValid, passwordCheck);
  }


  const loginWithOtp = () => {
    setOpenDialog(true);
  }

  const logiViaOtp = () => {
    const payloadData = new URLSearchParams()
    payloadData.append('phone', phoneNumber);
    payloadData.append('event', 'login-otp');
    axiosService.sendAuthOtp(payloadData).then((resp) => {
      loginWithOtp();
    }).catch((error) => {
    })
  }
  React.useEffect(() => {
   if(!!props.data.enteredMobileNumber){
       setPhoneNumber(props.data.enteredMobileNumber);
   }
  }, [props.data.enteredMobileNumber])


  return (
    <React.Fragment>
      {!!openDialog ? <FullScreenDialog openDialog={openDialog} setOpenDialog={setOpenDialog} headerText={'Enter Verification Code'} layout={() => openLoginWithOtp({
        enteredMobileNumber: phoneNumber
      }
      )} /> : ''}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            color: appTheme.color1,
            mt: 10
          }}
        >
          <CustomizedInputs key={"1"} placeholder="Enter Phone Number"
            label="Phone Number*" defaultValue={phoneNumber}
            type="number"
            setData={setPhoneNumber}
            isValidation={isPhoneNumberValid}
            checkValidation={checkPhoneNumberValidation}
            disabled={true}
            startAdornment={
              <InputAdornment position="start" sx={{ color: appTheme.color1 }}>
                <img
                  src={require("../../img/icons8-india-48.png")}
                  width="30"
                  alt="logo"
                  loading="lazy"
                />&nbsp;+91
              </InputAdornment>
            } />

          <CustomizedInputs key={"2"} placeholder="Enter Password"
            label="Password*" defaultValue={password}
            type="password"
            setData={setPassword}
            isValidation={isPasswordValid}
            checkValidation={checkPasswordValidation}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if(!getDevToolState()){
                  submitLogin(phoneNumber, password);
                }else{
                  navigate('/devTools')
                }
                
              }
            }} />

          <Stack spacing={2}>
            <Button onClick={() => {
              if(!getDevToolState()){
                submitLogin(phoneNumber, password);
              }else{
                navigate('/devTools')
              }
            }}
              sx={{
                color: appTheme.color0, bgcolor: appTheme.color12,
                "&:hover": {
                  bgcolor: appTheme.color12
                },
                focusVisible: false,
                pb: 1, pt: 1
              }}>Login</Button>

          </Stack>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 3,
            }}
          >
            <Root>
              <Divider sx={{
                "::before": {
                  borderTop: '1px solid #fff'
                }, "::after": {
                  borderTop: '1px solid #fff'
                }
              }}>
                <Chip sx={{ color: appTheme.color0, backgroundColor: appTheme.color1 }} label="OR" />
              </Divider>
              <Typography variant="h6" gutterBottom component="div">
                Login With <span onClick={() => {
                  if(!getDevToolState()){
                    logiViaOtp();
                  }else{
                    navigate('/devTools')
                  }
                }}
                  style={{ color: appTheme.color12, cursor: 'pointer' }}>OTP</span>
              </Typography>
            </Root>

          </Stack>

        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};
const LoginWithPasswordScreen = React.memo(LoginWithPassword);
export default LoginWithPasswordScreen;
