import OneSignal from 'react-onesignal';
import { localStorageKey } from './constants/LocalStorageKey';
import constant from './constants/Constatnt';


export default async function runOneSignal() {
  const appId = constant.appHostsAndIds.find(appHostAndId => appHostAndId.host === window.location.host)?.appId || '87761855-eae4-4281-801d-a00d2b04dc52';
  await OneSignal.init({ appId, allowLocalhostAsSecureOrigin: true});
  OneSignal.showSlidedownPrompt();
  OneSignal.getUserId().then((userId) => {
   sessionStorage.setItem(localStorageKey.USERID, userId);
  })
}