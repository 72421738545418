import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { appTheme } from '../theme/colorTheme';
import Typography from '@mui/material/Typography';
import { getImgSrc } from '../constants/Helper';
import { Button } from '@mui/material';

const PaymentGatewayScreen = ({ data, handleChecked, onSubmitPaymentGateway }) => {
    const [value, setValue] = React.useState(data[0].id);
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    React.useEffect(() => {
        handleChecked(data[0], true)  
    },[])
    return (
        <FormControl sx={{ color: appTheme.color1, paddingLeft: 2, width: '100%' }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={value}
                name="radio-buttons-group"
                onChange={handleChange}
            >
                {
                    data.map((item) => (
                        <FormControlLabel sx={{
                            position:'relative',
                            border: +item.id === +value ? `1px solid ${appTheme.color12}` : `1px solid ${appTheme.color11}`,
                            width: '100%',
                            marginBottom: 1,
                            borderRadius: 2,
                        }}
                            onClick={() => handleChecked(item, true)}
                            key={item.id} 
                            value={item.id}
                            control={<Radio sx={{
                                color: appTheme.color11,
                                '&.Mui-checked': {
                                    color: appTheme.color12,
                                },
                            }} />} label={<>
                                <div>
                                <img
                                    src={!!getImgSrc(item?.logo_url) ? getImgSrc(item?.logo_url) : require('../img/no-pictures.png')}
                                    alt="gateway"
                                    style={{ width: 35, verticalAlign: 'middle', marginRight: 10, backgroundColor: appTheme.color1, borderRadius: 2, padding: 1 }} />
                                <Typography variant="caption" sx={{fontSize:8, verticalAlign: 'middle', backgroundColor: appTheme.color11, borderRadius: 2, padding: .5, paddingLeft: 1, paddingRight: 1 }}>
                                    Instant approval
                                </Typography>
                                </div>
                              { +item.id === +value && <div style={{ position: 'absolute', right: 10, bottom: 10 }}>
                                    <Button sx={{
                                        margin: 0, pb:.4, pt:.4, fontSize: 10, color: appTheme.color0, bgcolor: appTheme.color12,
                                        "&:hover": {
                                            bgcolor: appTheme.color12
                                        },
                                        focusVisible: false,
                                    }}
                                    onClick={() => onSubmitPaymentGateway()}>Pay</Button>
                                </div>}
                            </>} />
                    ))
                }
            </RadioGroup>
        </FormControl>
    )
}
const PaymentGateway = React.memo(PaymentGatewayScreen);
export default PaymentGateway