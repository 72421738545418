import React, { useEffect, useState } from 'react'
import Marquee from './Marquee';
import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import constant from '../constants/Constatnt';
import { appTheme } from '../theme/colorTheme';
import { disableScroll, enableScroll } from '../constants/Helper';

const PaymentVideoScreen = ({closeVideo}) => {
    const [isFullView, setIsFullView] = useState(false);
    const [isUnMute, setIsUnMute] = useState(true);
    useEffect(() => {
        if (isFullView) {
          disableScroll();
        }else{
          enableScroll();
        }
      }, [isFullView])
  return (
    <div className={isFullView?"electroststicVideoFullView":"electroststicVideo"} >
            {
                !isFullView && <div style={{ width:'100%', padding:0, color: appTheme.color1, textAlign: 'center', zIndex: 9999999999999, 
                position: 'absolute', bottom: 0, }}>
                    <Marquee anoucmentText={'How to pay from upi to bank account'} 
                    isSlowAnimation={true} isLogo={false}/>
                 </div>
            }
            
          <div style={{position:'absolute', top:0, zIndex:2, color:'#fff', width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="opacityBg" onClick={() => {
              enableScroll();
              closeVideo();
            }}>
              <CloseIcon/>
            </div>
            <div style={{display:'flex'}}>
            {
             isFullView && <div className="opacityBg mrgnRight" onClick={() => {
              const vid = document.querySelector('video');
              vid.muted = !vid.muted;
              setIsUnMute((prvState => !prvState));
                }}>
               {isUnMute?<VolumeUpIcon />:<VolumeOffIcon/>}
              </div>
            }
            <div className="opacityBg" onClick={() => {
              setIsFullView((prvValue) => !prvValue);
              const vid = document.querySelector('video');
              vid.muted = !vid.muted;
              if(!isUnMute){
                vid.muted = true;
                setIsUnMute(true);
              }
              }}>
            {isFullView ? <CloseFullscreenIcon />: <OpenInFullIcon />}
            </div>
            </div>
            
          </div>
            <video loop autoPlay muted playsInline>
              <source src={constant.howToPayBankVid} type="video/mp4"/>
            </video>
          </div>
  )
}
const PaymentVideo = React.memo(PaymentVideoScreen);
export default PaymentVideo;