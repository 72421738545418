export const localStorageKey = {
    APITOKEN: 'apiToken',
    WALLETBALANCE: 'walletBalance',
    USERNAME: 'userName',
    MOBILENUMBER: 'mobileNumber',
    CUSTOMERDATA: 'customerData',
    SIMNUMBER: 'simNumber',
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    SELECTEDDOBDATE: 'selectedDobDate',
    EMAILID: 'emailId',
    SELECTEDPRODUCTPLAN: 'selectedProductPlan',
    PRODUCTOFFERS: 'productOffers',
    FLOWDATA: 'flowData',
    SERVERURLKEY: 'serverUrl',
    AVATAR: 'avatar',
    SETCREATEIDDATA: 'setCreateIdData',
    SETPAYMENTDATA: 'setPaymentData',
    SETDEPOSITORWITHDRAWIDDATA: 'setDepositOrWithdrawData',
    SETIDSTABSTATE: 'idTabState',
    ISDEVTOOLOPEN:'isDevToolOpen',
    ISDOWNLOADAPKPOPUPOPEN:'isDownloadApkPopupOpen',
    ISREFEREMODALEOPEN:'isReferModalOpen',
    USERID:'userId',
    USERNUMBER:'userNumber',
    PUSHID:'push_id',
    USER_LOGGED_IN_TIME:'userLoggedInTime',
    ISPAYMENTGATEWAYIMAGE:'isPaymentGatewayImage',
    ISDOWNLOADAPPSNACKBAROPEN:'isDownloadAppsSnackbarOpen',
    ISJOYTOUROPEN:'isJoyTourOpen',
    SHOULDOPENREFERMODAL:'shouldOpenReferModal',
    TXN_SCREEN_LOGGED_IN_TIME:'txnScreenLoggedInTime',
}