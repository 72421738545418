import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { appTheme } from "../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialogScreen = (props) => {
  const handleDialogClose = () => {

    props.setOpenDialog(false); // Use the prop.
  };
  return (
    <>
      <Dialog
        fullScreen
        open={props.openDialog}
        sx={{ maxWidth: "600px", margin: '0 auto' }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleDialogClose()}
              aria-label="close"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
              {props.headerText}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
          {props.openDialog ? (props.layout()) : ""}
        </DialogContent>

      </Dialog>
    </>
  );
};
const FullScreenDialog = React.memo(FullScreenDialogScreen);
export default FullScreenDialog;
