import React from "react";
import {
  ThemeProvider,
  CssBaseline,
  Typography,
  Button,
  Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../theme/theme";
import CustomizedInputs from "../components/customComponent/CustomInput";
import CheckboxLabels from "../components/customComponent/Checkbox";
import constant from "../constants/Constatnt";
import { getImgSrc, userNameValidation } from "../constants/Helper";
import axiosService from "../axios-api-kit/axiosService";
import InformationDailog from "./InformationDailog";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";
import { useSelector } from "react-redux";

const CreateIdScreen = () => {
  const { getCreateIdState, setPaymentData, getUpdatedUser } = React.useContext(AuthContext);
  let navigate = useNavigate();
  const props = getCreateIdState();
  const { wallet_balance } = useSelector(store => store?.app?.userInfo);
  const [userName, setUserName] = React.useState("");
  const [depositCoin, setDepositCoin] = React.useState(props.min_refill);
  const [isUserNameValid, setIsUserNameValid] = React.useState(true);
  const [isDepositCoinValid, setIsDepositCoinValid] = React.useState(true);
  const [finalAmount, setFinalAmount] = React.useState(depositCoin);
  const [isWalletCheckboxChecked, setIsWalletCheckboxChecked] = React.useState(null);
  const [continueButtonText, setContinueButtonText] = React.useState('Continue to Pay');
  const [walletBalanceUsed, setWalletBalanceUsed] = React.useState(null);
  const [errorMsgUserName, setErrorMsgUserName] = React.useState('');
  const [errorMsgCoins, setErrorMsgCoins] = React.useState('');

  const [openInfo, setOpenInfo] = React.useState(false);
  const [responseStatus, setResponseStatus] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [navigateUrl, setNavigateUrl] = React.useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
  const [buttonTextCancel, setButtonTextCancel] = React.useState('');

  const handleOpenDialog = (isUserNameValidCheck, isDepositCoinValidCheck, userNameValueCheck, depositCoinValueCheck) => {
    if (isUserNameValidCheck && isDepositCoinValidCheck &&
      userNameValueCheck.length !== 0 && depositCoinValueCheck.length !== 0 && depositCoinValueCheck !== '0') {
      setPaymentData({
        requested_amount: +depositCoin,
        payment_method_amount: finalAmount,
        user_website_id: props.id,
        wallet_balance_used: +walletBalanceUsed,
        preferred_username:userNameValueCheck,
        transactionFor: constant.transactionFor.createIdDeposit
      })
      navigate('/payment');
    }

  };

  const checkUserNameValidation = React.useCallback((value) => {
    if (userNameValidation(value)) {
      setIsUserNameValid(true)
    } else {
      setErrorMsgUserName(`User name should be at least 3 characters and maxiumum 8 characters.`)
      setIsUserNameValid(false)
    }
  }, [])

  const checkDepositCoin = React.useCallback((value) => {
    if (value.length <= 0 || value === '0' || +value < +props.min_refill) {
      setErrorMsgCoins(`Minimum deposit amoutn should be at least ${props.min_refill} coins.`)
      setIsDepositCoinValid(false);
    } else {
      setIsDepositCoinValid(true);
    }
  }, [props.min_refill])

  const continueToPay = (userNameValueCheck, depositCoinValueCheck) => {
    checkUserNameValidation(userNameValueCheck);
    checkDepositCoin(depositCoinValueCheck);
    if (isWalletCheckboxChecked && +depositCoin <= +wallet_balance) {
      if (isUserNameValid && isDepositCoinValid && userNameValueCheck.length !== 0 && depositCoinValueCheck.length !== 0 && depositCoinValueCheck !== '0') {
        const payload = new URLSearchParams()
        payload.append('website_id', props.id)
        payload.append('requested_amount', +depositCoin)
        payload.append('wallet_balance_used', +walletBalanceUsed)
        payload.append('preferred_username', userNameValueCheck)
        axiosService.create_id_transaction(payload).then((res) => {
          setResponseStatus('success');
          setStatusMessage("Your request has been submitted successfully!");
          setButtonTextConfirm('Ok');
          setButtonTextCancel('Cancel');
          getUpdatedUser();
          setNavigateUrl('/');
          setOpenInfo(true);
        })
          .catch((error) => {
            setResponseStatus('error');
            setStatusMessage("Your request has been failed!");
            setButtonTextConfirm('Ok');
            setButtonTextCancel('Cancel');
            setNavigateUrl('');
            setOpenInfo(true);
          })
      }
    } else {
      handleOpenDialog(isUserNameValid, isDepositCoinValid, userNameValueCheck, depositCoinValueCheck);
    }

  }

  React.useEffect(() => {
    if (isWalletCheckboxChecked) {
      if (+depositCoin <= +wallet_balance) {
        setContinueButtonText('Pay From Wallet');
        setWalletBalanceUsed(depositCoin);
        setFinalAmount(depositCoin);
      } else {
        setContinueButtonText('Continue to pay');
        setWalletBalanceUsed(wallet_balance);
        setFinalAmount(Math.abs(depositCoin - wallet_balance))
      }
    } else {
      setWalletBalanceUsed(0);
      setContinueButtonText('Continue to pay')
      setFinalAmount(depositCoin);
    }
  }, [depositCoin, isWalletCheckboxChecked, wallet_balance])


  return (
    <React.Fragment>
      {!!openInfo ? <InformationDailog
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        status={responseStatus}
        statusMessage={statusMessage}
        buttonTextConfirm={buttonTextConfirm}
        buttonTextCancel={buttonTextCancel}
        navigateUrl={navigateUrl} /> : ''}

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            color: appTheme.color1,
            mt: 1
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 3,
            }}
          >
            <Box
              sx={{
                mb: 1,
                width: 50,
                height: 45,
                borderRadius: 2,
                backgroundColor: appTheme.color11,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={!!getImgSrc(props?.logo_url) ? getImgSrc(props.logo_url) : require('../img/no-pictures.png')}
                width="40"
                alt="logo"
                loading="lazy"
              />
            </Box>

            <Typography variant="body1" gutterBottom component="div">
              {props.name}
            </Typography>
            <Typography variant="caption" display="block">
              {props.access_url}
            </Typography>
            {/* <Typography variant="caption" display="block">
              {props.demo_id}
            </Typography> */}
          </Stack>

          <Stack
            sx={{ color: appTheme.color1, mb: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ color: appTheme.color7 }}
              >
                Min Refill
              </Typography>
              <Typography variant="caption" gutterBottom component="div">
                {props.min_refill}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ color: appTheme.color7 }}
              >
                Min Withdrawal
              </Typography>
              <Typography variant="caption" gutterBottom component="div">
                {props.min_withdrawal}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{ color: appTheme.color1, mb: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ color: appTheme.color7 }}
              >
                Min Maintaining Bal
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.min_balance_maintain}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Typography
                variant="caption"
                gutterBottom
                sx={{ color: appTheme.color7 }}
              >
                Max Withdrawal
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.max_withdrawal}
              </Typography>
            </Box>
          </Stack>
          <CustomizedInputs key={"2"} placeholder="Enter Username"
            label="Username*" defaultValue={userName}
            setData={setUserName}
            errorMsg={errorMsgUserName}
            isValidation={isUserNameValid}
            checkValidation={checkUserNameValidation} />
          <CustomizedInputs key={"3"} placeholder="Deposite Coins"
            label={`Coins* (Minimum deposit amount is ${props.min_refill} coins)`}
            type="number"
            errorMsg={errorMsgCoins}
            defaultValue={depositCoin}
            setData={setDepositCoin}
            isValidation={isDepositCoinValid}
            checkValidation={checkDepositCoin} />
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="left"
            sx={{ mt: 3, mb: 3, border: `1px dashed ${appTheme.color17}`, padding: 1, opacity: !(wallet_balance <= 0) ? 1 : .5 }}
          >
            <Box sx={{ padding: 1 }}>
              <CheckboxLabels disabled={!!(wallet_balance <= 0)} onChange={(e, newValue) => {
                setIsWalletCheckboxChecked(newValue);
              }} />
            </Box>

            <Typography
              variant="caption"
              display="block"
              gutterBottom
              sx={{ color: appTheme.color7 }}
            >
              Pay From Wallet
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ color: `white` }}
              >
                Current Wallet Balance {wallet_balance}
              </Typography>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Button onClick={() => {
              continueToPay(userName, depositCoin);
            }}
              sx={{
                color: appTheme.color0, bgcolor: appTheme.color12,
                "&:hover": {
                  bgcolor: appTheme.color12
                },
                focusVisible: false,
                pb: 1, pt: 1
              }}>
              <Typography
                variant="caption"
                display="block"
                gutterBottom>{continueButtonText} ₹{finalAmount}</Typography>
            </Button>

          </Stack>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
};

const CreateId = React.memo(CreateIdScreen);

export default CreateId;