import React, { useContext } from "react";
import {
    ThemeProvider,
    CssBaseline,
    Typography,
    Button,
    Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../theme/theme";
import CustomizedInputs from "../components/customComponent/CustomInput";
import axiosService from "../axios-api-kit/axiosService";
import InformationDailog from "./InformationDailog";
import RadioButton from "../components/customComponent/RadioButton";
import { useSelector } from "react-redux";
import { appTheme } from "../theme/colorTheme";
import constant from "../constants/Constatnt";
import { AuthContext } from "../context/AuthProvider";

const WithdrawWalletScreen = () => {
    const { getUpdatedUser } = useContext(AuthContext);
    const { wallet_balance } = useSelector(store => store?.app?.userInfo);
    const minWithdrawalCoins = useSelector(store => store?.app?.commonInfo?.min_withdraw_wallet) || constant.minWithdrawalCoins;
    const [withdrawCoin, setWithdrawCoin] = React.useState("");
    const [isWithdrawCoinValid, setIsWithdrawCoinValid] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState("");

    const [openInfo, setOpenInfo] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState('');
    const [statusMessage, setStatusMessage] = React.useState('');
    const [navigateUrl, setNavigateUrl] = React.useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
    const [buttonTextCancel, setButtonTextCancel] = React.useState('');


    const checkWithdrawCoin = (value, isClickContinue) => {
        if (+value <= 0 || +value < minWithdrawalCoins) {
            setErrorMsg(`Minimum withdrawal amount should be at least ${minWithdrawalCoins} coins.`)
            setIsWithdrawCoinValid(false);
        } else if(+value > wallet_balance){
            setErrorMsg(`You do not have enough balance in your wallet to perform a transfer.`)
            setIsWithdrawCoinValid(false);
        }else {
            setIsWithdrawCoinValid(true);
        }
    }

    const continueToWithdraw = (WithdrawCoinValueCheck) => {
        checkWithdrawCoin(WithdrawCoinValueCheck, true);
        if (isWithdrawCoinValid && WithdrawCoinValueCheck.length !== 0 && WithdrawCoinValueCheck !== '0' && WithdrawCoinValueCheck <= +wallet_balance && +WithdrawCoinValueCheck >= minWithdrawalCoins) {

            const payload = new URLSearchParams()
            payload.append('requested_amount', +withdrawCoin)
            axiosService.withdrawWalletTransaction(payload).then((res) => {
                setResponseStatus('success');
                setStatusMessage("Your request has been submitted successfully!");
                setButtonTextConfirm('Ok')
                setButtonTextCancel('Cancel')
                getUpdatedUser();
                setNavigateUrl('/');
                setOpenInfo(true)
            })
                .catch((error) => {
                    setResponseStatus('error');
                    setStatusMessage("Your request has been failed!");
                    setButtonTextConfirm('Ok')
                    setButtonTextCancel('Cancel')
                    setNavigateUrl('');
                    setOpenInfo(true)
                })
        }
    }

    React.useEffect(() => {

    }, [])

   if(!minWithdrawalCoins || (wallet_balance === undefined)) return;
    return (
        <React.Fragment>
            {!!openInfo ? <InformationDailog
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                status={responseStatus}
                statusMessage={statusMessage}
                buttonTextConfirm={buttonTextConfirm}
                buttonTextCancel={buttonTextCancel}
                navigateUrl={navigateUrl} /> : ''}
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    sx={{
                        color: appTheme.color1,
                        mt: 1
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            mb: 5,
                        }}
                    >
                        <Box
                            sx={{
                                mb: 2
                            }}
                        >
                            <img
                                width="120px"
                                src={require(`../img/withdraw_icon.png`)}
                                alt="noOffer"
                                loading="lazy"
                            />
                        </Box>
                        <Typography variant="caption" display="block" sx={{mt:-4}}>
                            Wallet Balance
                        </Typography>
                        <Typography variant="h6" gutterBottom component="div">
                            ₹{wallet_balance}
                        </Typography>

                    </Stack>
                    <CustomizedInputs key={"1"} placeholder="Enter Withdraw Coins"
                        label={`Withdraw Coins* (Minimum Withdraw amount is ${minWithdrawalCoins} coins)`}
                        type="number"
                        errorMsg={errorMsg}
                        defaultValue={withdrawCoin}
                        setData={setWithdrawCoin}
                        isValidation={isWithdrawCoinValid}
                        checkValidation={checkWithdrawCoin} />
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="left"
                        sx={{ mt: 3, mb: 3, border: `1px dashed ${appTheme.color17}`, padding: 1 }}
                    >
                        <RadioButton />
                        <Typography
                            variant="caption"
                            display="block"
                            sx={{ color: appTheme.color7, mt: 1.3 }}
                        >
                            Withdraw to Bank
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Button onClick={() => {
                            continueToWithdraw(withdrawCoin);
                        }}
                            sx={{
                                color: appTheme.color0, bgcolor: appTheme.color12,
                                "&:hover": {
                                    bgcolor: appTheme.color12
                                },
                                focusVisible: false,
                                pb:1, pt: 1
                            }}>Submit</Button>

                    </Stack>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
};
const WithdrawWallet = React.memo(WithdrawWalletScreen)

export default WithdrawWallet;
