import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axiosService from "../axios-api-kit/axiosService";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import TitlebarImageList from "./ImageList";
import { Button, Divider, Skeleton } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SnackBar from './SnackBar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { appTheme } from "../theme/colorTheme";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import { getImgSrc } from "../constants/Helper";
import MenuConfiguration from "./MenuConfiguration";
import InformationDailog from "./InformationDailog";
import dep from '../img/dep.svg';
import wid from '../img/wid.svg';
import { useSelector } from "react-redux";
import constant from "../constants/Constatnt";

const MyIdHomeListScreen = (props) => {
    const openDepositOrWithdrawDailog = (selectedId, openFor) => {
        props.openDepositOrWithdrawDailog(selectedId, openFor);
    }

    const selectedId = React.useCallback((action) => {
        props.getSelectedId(props.itemList.id, action)
    }, [props.itemList.id, props.getSelectedId])
    return (

        <>
            <div className="myid">
                <div className="dot-bar">
                    <MenuConfiguration
                        selectedId={selectedId}
                        isChangePasswordUnderMaintenance={props.itemList?.website?.is_change}
                        isCloseIdUnderMaintenance={props.itemList?.website?.is_close}
                        handleClick={props.handleClick}
                    />
                </div>
                <div className="idConatainer">
                    <div className="myid-inner"><img src={!!getImgSrc(props.itemList?.website?.logo_url) ? getImgSrc(props.itemList.website.logo_url) : require('../img/no-pictures.png')}
                        alt="logo"
                        loading="lazy" /></div>
                    <div className="id-detail">
                        {/* <div className="id-name"> {props.itemList?.website?.name}</div> */}
                        <div className="id-url-name"><span>
                            <a href={`https://${props.itemList?.website?.access_url}`} target='blank' className="id-url-text">
                                {props.itemList?.website?.access_url} <OpenInNewIcon fontSize="small" sx={{ fontSize: 14, verticalAlign: 'middle', padding: 0 }} />
                            </a>
                        </span>
                        </div>
                        <div className="user-id">User ID : <CopyToClipboard text={props.itemList?.username}>
                            <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => props.handleClick('Copied to clipboard!')}>
                                {props.itemList?.username}&nbsp;
                                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard></div>
                        <div className="password">Password : <CopyToClipboard text={props.itemList?.password}>
                            <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => props.handleClick('Copied to clipboard!')}>
                                {props.itemList?.password}&nbsp;
                                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard></div>
                        <div className="change-pass">Change Password on first login</div>

                    </div>
                </div>

                <div className="id-footer">

                    <div className="despositNwihdraw" style={{ opacity: props?.itemList?.website?.is_deposit ? 0.4 : 1 }}  onClick={() => {
                        if (!props?.itemList?.website?.is_deposit) {
                            openDepositOrWithdrawDailog(props.itemList, 'depositId')
                        } else {
                            props.handleClick('Site Under Maintenance!');
                        }
                    }}><img src={wid} alt="withdraw" style={{ marginRight: '5px', verticalAlign: 'middle', marginTop: '-3px', rotate: '180deg' }} />

                        {
                            props?.itemList?.website?.is_deposit ? <>
                                <span>Deposit</span>&nbsp;
                                <ConstructionOutlinedIcon sx={{ fontSize: 16, verticalAlign: 'middle' }} />
                            </> :
                                <span>Deposit</span>
                        }
                    </div>
                    <div className="line"></div>
                    <div className="despositNwihdraw" style={{ opacity: props?.itemList?.website?.is_withdraw ? 0.4 : 1 }} onClick={() => {
                        if (!props?.itemList?.website?.is_withdraw) {
                            openDepositOrWithdrawDailog(props.itemList, 'withdrawId')
                        } else {
                            props.handleClick('Site Under Maintenance!');
                        }

                    }}><img src={dep} alt="deposit" style={{ marginRight: '5px', verticalAlign: 'middle', marginTop: '1px', rotate: '180deg' }} />
                        {
                            props?.itemList?.website?.is_withdraw ? <>
                                <span>Withdraw</span>&nbsp;
                                <ConstructionOutlinedIcon sx={{ fontSize: 16, verticalAlign: 'middle' }} />
                            </>
                                :
                                <span>Withdraw</span>
                        }

                    </div>
                </div>
            </div>


        </>




    );
};

const MyIdHomeList = React.memo(MyIdHomeListScreen)

const MyIdHomeDetailCard = () => {
    const [open, setOpen] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState('');
    const [statusMessage, setStatusMessage] = React.useState('');
    const [navigateUrl, setNavigateUrl] = React.useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
    const [buttonTextCancel, setButtonTextCancel] = React.useState('');
    const [selectedId, setSelectedId] = React.useState('');
    const { setDepositOrWithdrawIdData } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const myIdData = useSelector(store => store?.app?.myIdData);
    const [snackBarMessage, setSnackBarMessage] = React.useState('');
    const handleClick = React.useCallback((message) => {
        setSnackBarMessage(message)
        setOpen(true);
    }, []);
    const payload = new URLSearchParams();
    const getSelectedId = React.useCallback((selectedId, action) => {
        setSelectedId(selectedId);
        payload.append('user_website_id', selectedId);
        switch (action) {
            case constant.transactionType.changeIdPasswordRequest:
                axiosService.channgeIdPassword(payload).then((response) => {
                    invokeOnSuccess();
                }).catch((error) => {
                    invokeOnFailure();
                })
                break;
            case constant.transactionType.closeIdRequest:
                setResponseStatus('warning');
                setStatusMessage("Do you want to close this id?");
                setButtonTextConfirm('Yes')
                setButtonTextCancel('Cancel')
                setNavigateUrl('');
                setOpenInfo(true)
                break;
            default:
                break;
        }
        
       
    }, [])

    const invokeOnSuccess = () => {
        setResponseStatus('success');
        setStatusMessage("Your request has been sucessfully placed !");
        setButtonTextConfirm('Ok')
        setButtonTextCancel('Cancel')
        setNavigateUrl('');
        setOpenInfo(true)
    }

    const invokeOnFailure = () => {
        setResponseStatus('error');
        setStatusMessage("Your request has been failed !");
        setButtonTextConfirm('Ok')
        setButtonTextCancel('Cancel')
        setNavigateUrl('');
        setOpenInfo(true)
    }

    const confirmationIsValid = () => {
        payload.append('user_website_id', selectedId);
        payload.append('reason_remarks', 'I want to close this id account');
        payload.append('to_wallet', false);
        axiosService.closeId(payload).then((response) => {
          invokeOnSuccess();
        }).catch((error) => {
          invokeOnFailure();
        })
      };

    const openDepositOrWithdrawDailog = (selectedId, openForScreen) => {
        setDepositOrWithdrawIdData(selectedId);
        navigate(`/${openForScreen}`)
    }

    return (
        <>
            {!!openInfo ? <InformationDailog
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                status={responseStatus}
                statusMessage={statusMessage}
                confirmation={confirmationIsValid}
                buttonTextConfirm={buttonTextConfirm}
                buttonTextCancel={buttonTextCancel}
                navigateUrl={navigateUrl} /> : ''}
            <SnackBar
                setOpen={setOpen}
                open={open}
                message={snackBarMessage}
            />
            <SnackBar
                setOpen={setOpen}
                open={open}
                message={snackBarMessage}
            />
            {
                !!myIdData ?
                    !!myIdData && myIdData.length !== 0 ?
                        <>


                            <Stack
                                sx={{ color: appTheme.color1, width: '100%', mt: 3 }}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        My IDs
                                    </Typography>
                                    <Divider sx={{ borderTop: `1px solid ${appTheme.color12}`, width: "55px" }} />
                                </Box>
                                <Box>
                                    <Button
                                        // startIcon={<AddCircleSharpIcon fontSize="small" />}
                                        onClick={() => {
                                            navigate('/ids?index=1')
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            pr: .7,
                                            pl: .7,
                                            fontSize: 10,
                                            bgcolor: appTheme.color12,
                                            "&:hover": {
                                                bgcolor: appTheme.color12
                                            },
                                            color: appTheme.color0,
                                            mr: 1,
                                            focusVisible: false,
                                        }}
                                    >
                                        <Typography component="span" variant="caption" sx={{textTransform:"capitalize"}}>Create New</Typography>

                                    </Button>

                                    <Button
                                        onClick={() => {
                                            navigate('/ids?index=0')
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            pr: .7,
                                            pl: .7,
                                            fontSize: 10,
                                            bgcolor: appTheme.color12,
                                            "&:hover": {
                                                bgcolor: appTheme.color12
                                            },
                                            color: appTheme.color0,
                                            mr: 0,
                                            focusVisible: false,
                                        }}
                                    >
                                        <Typography component="span" variant="caption" sx={{textTransform:"capitalize"}}>View All</Typography>

                                    </Button>

                                </Box>
                            </Stack>
                            <Box sx={{ mt: 3 }}>
                                {
                                    myIdData.map((item) => (
                                        <div key={item.id}>

                                            <MyIdHomeList itemList={item} openDepositOrWithdrawDailog={openDepositOrWithdrawDailog} handleClick={handleClick} getSelectedId={getSelectedId} />

                                        </div>
                                    ))

                                }
                            </Box>
                            <Button
                                    onClick={() => {
                                        navigate('/ids?index=0')
                                    }}
                                    size="small"
                                    variant="contained"
                                    color="inherit"
                                    sx={{
                                        width: '100%',
                                        p:1,
                                        bgcolor: appTheme.color12,
                                        "&:hover": {
                                            bgcolor: appTheme.color12
                                        },
                                        color: appTheme.color0,
                                        mr: 0,
                                        focusVisible: false,
                                    }}
                                >
                                    <Typography component="span" variant="caption" fontSize={14} sx={{textTransform:"capitalize"}}>View All</Typography>

                                </Button>
                        </>

                        : <Box>
                             <BasicInfo />
                                    <TitlebarImageList />
                        </Box>

                    : <Box sx={{ width: '100%' }}>
                        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                        <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                    </Box>
            }

        </>

    );
};

export default MyIdHomeDetailCard;
