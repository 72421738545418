import React, { useCallback } from "react";
import { useBlocker } from "../utils/useBlocker";
import { useConfirm } from "../utils/useConfirmation";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Typography } from '@mui/material';
import { appTheme } from '../theme/colorTheme';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomPrompt = ({ when = true, message, setOpenInfo, callbackAfterConfirmation }) => {
  const {
    isOpen,
    proceed,
    cancel,
    onConfirm,
    hasConfirmed,
    resetConfirmation
  } = useConfirm();

  let blocker = useCallback(
    async (tx) => {
      if (await onConfirm()) {
        setOpenInfo(false)
        await callbackAfterConfirmation();
        resetConfirmation();
        tx.retry();
      }
    },
    [resetConfirmation, onConfirm]
  );

  useBlocker(blocker, when && !hasConfirmed);

  return (
    <Dialog
    sx={{
      "& .MuiPaper-root": {
        backgroundColor: appTheme.color3
      }
    }}
    open={isOpen}
    TransitionComponent={Transition}
    keepMounted
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle sx={{ color: appTheme.color1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <WarningRoundedIcon fontSize='large' fontWeight='800' color="warning" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description" sx={{ color: appTheme.color1, textAlign: 'center' }}>
        <Typography variant="caption" display="block" fontSize={12}>
        {message}
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button onClick={proceed} sx={{
        fontSize: 11,
        color: appTheme.color0, bgcolor: appTheme.color12,
        "&:hover": {
          bgcolor: appTheme.color12
        },
        focusVisible: false
      }}>Yes</Button>
       <Button sx={{
        fontSize: 11,
        color: appTheme.color0, bgcolor: appTheme.color12,
        "&:hover": {
          bgcolor: appTheme.color12
        },
        focusVisible: false
      }} onClick={cancel}>Cancel</Button> 
    </DialogActions>
  </Dialog>
  );
};

export default CustomPrompt;
