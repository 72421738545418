import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MenuConfiguration from "./MenuConfiguration";
import NoData from "./NoData";
import axiosService from "../axios-api-kit/axiosService";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import {  Skeleton } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SnackBar from './SnackBar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InformationDailog from "./InformationDailog";
import { appTheme } from "../theme/colorTheme";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import { getImgSrc } from "../constants/Helper";
import dep from '../img/dep.svg';
import wid from '../img/wid.svg';
import constant from "../constants/Constatnt";

const MyIdListScreen = ({ itemList, openDpositOrWithdrawDailog, handleClick, getSelectedId }) => {
  const openDpositOrWithdrawDailogEvent = (selectedId, openFor) => {
    openDpositOrWithdrawDailog(selectedId, openFor);
  }

  const selectedId = React.useCallback((action) => {
    getSelectedId(itemList.id, action)
  }, [itemList.id, getSelectedId])
  return (
    <>
    <div className="myid">
                <div className="dot-bar">
                    <MenuConfiguration
                        selectedId={selectedId}
                        isChangePasswordUnderMaintenance={itemList?.website?.is_change}
                        isCloseIdUnderMaintenance={itemList?.website?.is_close}
                        handleClick={handleClick}
                    />
                </div>
                <div className="idConatainer">
                    <div className="myid-inner"><img src={!!getImgSrc(itemList?.website?.logo_url) ? getImgSrc(itemList.website.logo_url) : require('../img/no-pictures.png')}
                        alt="logo"
                        loading="lazy" /></div>
                    <div className="id-detail">
                        {/* <div className="id-name"> {itemList?.website?.name}</div> */}
                        <div className="id-url-name"><span>
                            <a href={`https://${itemList?.website?.access_url}`} target='blank' className="id-url-text">
                                {itemList?.website?.access_url} <OpenInNewIcon fontSize="small" sx={{ fontSize: 14, verticalAlign: 'middle', padding: 0 }} />
                            </a>
                        </span>
                        </div>
                        <div className="user-id">User ID : <CopyToClipboard text={itemList?.username}>
                            <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => handleClick('Copied to clipboard!')}>
                                {itemList?.username}&nbsp;
                                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard></div>
                        <div className="password">Password : <CopyToClipboard text={itemList?.password}>
                            <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 12 }} onClick={() => handleClick('Copied to clipboard!')}>
                                {itemList?.password}&nbsp;
                                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                        </CopyToClipboard></div>
                        <div className="change-pass">Change Password on first login</div>

                    </div>
                </div>

                <div className="id-footer">

                    <div className="despositNwihdraw" style={{ opacity: itemList?.website?.is_deposit ? 0.4 : 1 }} onClick={() => {
                        if (!itemList?.website?.is_deposit) {
                          openDpositOrWithdrawDailogEvent(itemList, 'depositId')
                        } else {
                            handleClick('Site Under Maintenance!');
                        }
                    }}><img src={wid} alt="withdraw" style={{ marginRight: '5px', verticalAlign: 'middle', marginTop: '-3px', rotate: '180deg' }} />

                        {
                            itemList?.website?.is_deposit ? <>
                                <span>Deposit</span>&nbsp;
                                <ConstructionOutlinedIcon sx={{ fontSize: 16, verticalAlign: 'middle' }} />
                            </> :
                                <span>Deposit</span>
                        }
                    </div>
                    <div className="line"></div>
                    <div className="despositNwihdraw" style={{ opacity: itemList?.website?.is_withdraw ? 0.4 : 1 }} onClick={() => {
                        if (!itemList?.website?.is_withdraw) {
                          openDpositOrWithdrawDailogEvent(itemList, 'withdrawId')
                        } else {
                            handleClick('Site Under Maintenance!');
                        }

                    }}><img src={dep} alt="deposit" style={{ marginRight: '5px', verticalAlign: 'middle', marginTop: '1px', rotate: '180deg' }} />
                        {
                            itemList?.website?.is_withdraw ? <>
                                <span>Withdraw</span>&nbsp;
                                <ConstructionOutlinedIcon sx={{ fontSize: 16, verticalAlign: 'middle' }} />
                            </>
                                :
                                <span>Withdraw</span>
                        }

                    </div>
                </div>
            </div>
    
    </>

  );
};

const MyIdList = React.memo(MyIdListScreen)

const MyIdDetailCard = () => {
  const [open, setOpen] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [responseStatus, setResponseStatus] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [navigateUrl, setNavigateUrl] = React.useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
  const [buttonTextCancel, setButtonTextCancel] = React.useState('');
  const { setDepositOrWithdrawIdData } = React.useContext(AuthContext);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  
  let navigate = useNavigate();
  const [myIdData, setMyIdData] = React.useState(null);
  const getIdData = () => {
    axiosService.getUserIds().then((response) => {
      setMyIdData(response.data)
    })
      .catch((error) => {
      })
  }
  const payload = new URLSearchParams();
  const getSelectedId = React.useCallback((selectedId, action) => {
    setSelectedId(selectedId);
    payload.append('user_website_id', selectedId);
    switch (action) {
        case constant.transactionType.changeIdPasswordRequest:
            axiosService.channgeIdPassword(payload).then((response) => {
                invokeOnSuccess();
            }).catch((error) => {
                invokeOnFailure();
            })
            break;
        case constant.transactionType.closeIdRequest:
        setResponseStatus('warning');
        setStatusMessage("Do you want to close this id?");
        setButtonTextConfirm('Yes')
        setButtonTextCancel('Cancel')
        setNavigateUrl('');
        setOpenInfo(true)
            break;
        default:
            break;
    }
    
   
}, [])

const invokeOnSuccess = () => {
    setResponseStatus('success');
    setStatusMessage("Your request has been sucessfully placed !");
    setButtonTextConfirm('Ok')
    setButtonTextCancel('Cancel')
    setNavigateUrl('');
    setOpenInfo(true)
}

const invokeOnFailure = () => {
    setResponseStatus('error');
    setStatusMessage("Your request has been failed !");
    setButtonTextConfirm('Ok')
    setButtonTextCancel('Cancel')
    setNavigateUrl('');
    setOpenInfo(true)
}


  const openDpositOrWithdrawDailog = (selectedId, openForScreen) => {
    setDepositOrWithdrawIdData(selectedId);
    navigate(`/${openForScreen}`)
  }
  const handleClick = React.useCallback((message) => {
    setSnackBarMessage(message);
    setOpen(true);
  }, []);
  const confirmationIsValid = () => {
    payload.append('user_website_id', selectedId);
    payload.append('reason_remarks', 'I want to close this id account');
    payload.append('to_wallet', false);
    axiosService.closeId(payload).then((response) => {
      invokeOnSuccess();
    }).catch((error) => {
      invokeOnFailure();
    })
  };


  React.useEffect(() => {
    getIdData();
  }, []);
  return (
    <>
      {!!openInfo ? <InformationDailog
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        status={responseStatus}
        statusMessage={statusMessage}
        buttonTextConfirm={buttonTextConfirm}
        buttonTextCancel={buttonTextCancel}
        confirmation={confirmationIsValid}
        navigateUrl={navigateUrl} /> : ''}
      <SnackBar
        setOpen={setOpen}
        open={open}
        message={snackBarMessage}
      />



      {
        !!myIdData ? <Stack spacing={0}>
          {
            myIdData.length !== 0 ? myIdData.map((item) => (
              <MyIdList itemList={item} key={item.id} openDpositOrWithdrawDailog={openDpositOrWithdrawDailog} handleClick={handleClick}
                getSelectedId={getSelectedId} />
            )) : <NoData noDataText="You don't have any ID yet." />
          }
        </Stack> : <Box sx={{ width: '100%' }}>
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
          <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
        </Box>
      }

    </>

  );
};

export default MyIdDetailCard;
